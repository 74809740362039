@font-face {
  font-family: 'CustomFont';
  src: url('./../../assets/fonts/century-gothic/century-gothic.ttf') format('woff2');
}

.download-app-section {
  font-family: CustomFont, sans-serif;
  margin-bottom: 40px;
  margin-top: 20px;
  div.row {
    align-items: center;
  }

  .download-title {
    font-size: 2rem; /* Increased font size for better visibility */
    font-weight: bold;
    color: var(--main-color-one); /* Change to your desired color */
    margin-bottom: 15px; /* Added space below title */
  }

  .description {
    margin-top: 10px;
    font-size: 1rem; /* Increased for better readability */
    text-align: justify;
    color: #333; /* Set a color for the description */
  }

  .list-download {
    margin: 30px 0;
    display: flex;
    gap: 14px; /* Increased gap for better spacing */

    .button-download {
      display: flex;
      align-items: center;
      border-radius: 10px; /* Rounded corners */
      padding: 10px 16px; /* Increased padding for better click area */
      max-width: 300px;
      min-width: 200px;
      justify-content: space-around;
      background-color: var(--main-color-one);
      transition: background-color 0.3s ease; /* Added transition for hover effect */

      svg {
        color: #fff;
        font-size: 2.5rem; /* Increased icon size for visibility */
      }

      .groupe-tele {
        display: flex;
        flex-direction: column;
        align-items: center;

        span:first-child {
          font-size: 0.85rem; /* Increased font size */
        }

        span:last-child {
          font-size: 1rem; /* Increased font size */
          font-weight: bold;
        }

        span {
          color: #fff;
        }
      }

      &:hover {
        background-color: var(--main-color-one); /* Darken background on hover */
      }
    }
  }

  @media (max-width: 640px) {
    .download-title{
      text-align: center;
      margin-bottom: 24px;
    }
    .list-download {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .button-download {
      width: 80%;
      justify-content:center !important;
      gap: 10px !important;
    }
  }
}