:root {
  --main-color-one: #fd8000 /* f79d68; */;
  --main-color-one-rgb: 253,128,0 /* 220, 110, 20 */;
  --secondary-color: #263272;
  --third-color: #fec55c;
  --third-color-rgb: 254, 197, 92;
  --secondary-color-rgb: 255, 140, 50;
  --gradient-circle: radial-gradient(circle, var(--main-color-one) 100%, rgba(var(--third-color-rgb), 0.9) 100%);
  --section-bg-1: #F6F7F8;
  --section-bg-2: #F2F4F7;
  --footer-bg-1: #27282B;
  --footer-bg-2: #3a2612;
  --copyright-bg-1: #323336;
  --heading-color: #3a2612;
  --body-color: #667085;
  --gray-color: #f8f8f8;
  --input-color: #EAECF0;
  --paragraph-color: #667085;
  --light-color: #999;
  --success-color: #00C897;
  --success-color-rgb: 0, 200, 151;
  --heading-font: "Plus Jakarta Sans", sans-serif;
  --body-font: "Manrope", sans-serif;
  --jakarta-font: "Plus Jakarta Sans", sans-serif;
  --manrope-font: "Manrope", sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  color: var(--body-color);
  overflow: hidden;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-width: 480px) {
  body {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  body {
    font-size: 14px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--extra-light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

.list-style-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}

span {
  display: inline-block;
}

.form--control {
  width: 100%;
}

.radius-20 {
  border-radius: 20px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-5 {
  border-radius: 5px;
}

.color-light {
  color: var(--light-color) !important;
}

.section-bg-2 {
  background-color: var(--section-bg-2);
}

.footer-bg-2 {
  background-color: #000;
}

.color-one {
  color: var(--main-color-one);
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.center-text {
  text-align: center;
}

.tab-content-item {
  display: none;
}

.tab-content-item.active {
  display: block;
  -webkit-animation: 1s fade-effects;
          animation: 1s fade-effects;
}

@-webkit-keyframes fade-effects {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-effects {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.pat-50 {
  padding-top: 50px;
}

.pat-100 {
  padding-top: 100px;
}

@media screen and (max-width: 991px) {
  .pat-100 {
    padding-top: 70px !important;
  }

  .pab-100 {
    padding-bottom: 70px !important;
  }

  .pab-75 {
    padding-bottom: 45px !important;
  }

  .pat-50 {
    padding-top: 35px !important;
  }

  .pab-50 {
    padding-bottom: 35px !important;
  }
}

.pab-50 {
  padding-bottom: 50px;
}

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

.btn-wrapper {
  display: block;
}
.btn-wrapper .cmn-btn {
  color: var(--paragraph-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--body-font);
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  padding: 7px 35px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media only screen and (max-width: 575.98px) {
  .btn-wrapper .cmn-btn {
    padding: 10px 25px;
    font-size: 15px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn {
    padding: 5px 15px;
    font-size: 14px;
  }
}
.btn-wrapper .cmn-btn.btn-bg-1 {
  background: var(--main-color-one);
  color: #fff;
  border: 2px solid var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-bg-1:hover {
  background: none;
  color: var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-border {
  border: 1px solid #f1f1f1;
}
.btn-wrapper .cmn-btn.btn-border:hover {
  background-color: var(--main-color-one);
  color: #fff;
  border-color: var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1 {
  padding: 7px 35px;
  border: 2px solid var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1.color-one {
  color: var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1:hover {
  background: var(--main-color-one);
  color: #fff;
}
@media only screen and (max-width: 575.98px) {
  .btn-wrapper .cmn-btn.btn-outline-1 {
    padding: 8px 23px;
    font-size: 15px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-outline-1 {
    padding: 4px 13px;
    font-size: 14px;
  }
}
.btn-wrapper .cmn-btn.btn-bg-white {
  background-color: #fff;
  color: var(--heading-color);
  border: 2px solid #fff;
}
.btn-wrapper .cmn-btn.btn-bg-white:hover {
  background-color: var(--main-color-one);
  border-color: #fff;
  color: #fff;
  border-radius: 30px;
}
.btn-wrapper .cmn-btn.btn-medium {
  padding: 7px 20px;
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-medium {
    padding: 5px 15px;
    font-size: 14px;
  }
}
.btn-wrapper .cmn-btn.btn-small {
  padding: 5px 15px;
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-small {
    padding: 5px 12px;
    font-size: 14px;
  }
}

.center-text {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.section-title.text-left {
  text-align: left;
}
.section-title.white-color {
  color: #fff;
}
.section-title.white-color .title {
  color: #fff;
}
.section-title.center-text {
  text-align: center;
  max-width: 650px;
  margin: auto;
}
.section-title.section-border-bottom {
  border-bottom: 1px solid rgba(221, 221, 221, 0.4);
  padding-bottom: 30px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title.section-border-bottom {
    padding-bottom: 20px;
  }
}

.section-title-line {
  position: relative;
  height: 20px;
}
.section-title-line::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-image: url(../img/section-shapes2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  width: auto;
}
.section-title .title {
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--heading-color);
  font-family: var(--heading-font);
  position: relative;
  margin: -6px 0 0;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .section-title .title {
    font-size: 45px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .section-title .title {
    font-size: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title .title {
    font-size: 36px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-title .title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 375px) {
  .section-title .title {
    font-size: 25px;
    line-height: 28px;
  }
}
.section-title .section-para {
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
}
@media only screen and (max-width: 480px) {
  .section-title .btn-wrapper .view-all {
    font-size: 15px;
  }
}

.section-title-three .title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-title-three .title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-title-three .title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .section-title-three .title {
    font-size: 24px;
    line-height: 32px;
  }
}
.section-title-three .title:hover::before {
  background-color: var(--main-color-one);
}
.section-title-three .title::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -25px;
  height: 50px;
  width: 50px;
  background-color: var(--third-color);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title::before {
    left: -10px;
    top: -10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title::before {
    left: 5px;
    top: 0;
  }
}
.section-title-three .title::after {
  content: "";
  position: absolute;
  left: -45px;
  top: -25px;
  height: 30px;
  width: 30px;
  background-image: url(../img/section-shapes4.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title::after {
    left: -25px;
    top: -25px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title::after {
    left: -10px;
    top: -10px;
  }
}
.section-title-three .section-para {
  margin-top: 20px;
}

.section-title-btn .view-all {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  color: var(--light-color);
  border: 1px solid var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.section-title-btn .view-all:hover {
  background-color: var(--light-color);
  color: #fff;
}

.js-select {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 15px;
  font-weight: 400;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
  -webkit-transition: 300ms;
  transition: 300ms;
  padding: 0 30px 0 15px;
}
.js-select:focus {
  border: 1px solid var(--input-color);
}
.js-select .nice-select-search-box {
  height: 100%;
}
.js-select .nice-select-search-box .nice-select-search {
  vertical-align: top;
}
.js-select:after {
  border-bottom: 2px solid var(--light-color);
  border-right: 2px solid var(--light-color);
  height: 7px;
  margin-top: -2px;
  right: 20px;
  width: 7px;
}
@media only screen and (max-width: 375px) {
  .js-select:after {
    right: 10px;
  }
}
.js-select.open {
  border: 1px solid #ddd;
}
.js-select.open .list {
  width: 100%;
}
.js-select .list {
  width: 100%;
  margin-top: 10px;
}
.js-select .list .selected.focus {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.js-select .list .option:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
  font-weight: 500;
}

.js-select.select-style-two {
  display: block;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.js-select.select-style-two.open::after {
  border-color: transparent transparent var(--paragraph-color);
  border-width: 0px 4px 5px 4px;
}
.js-select.select-style-two:after {
  border-color: var(--paragraph-color) transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  /* height: 0; */
  left: auto;
  right: 11px;
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
@media only screen and (max-width: 375px) {
  .js-select.select-style-two:after {
    right: 10px;
  }
}

.append-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 5px;
}

.faq-contents .faq-item {
  padding: 0;
  border: 1px solid var(--input-color);
  border-radius: 10px;
}
.faq-contents .faq-item.open {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  padding: 0px;
}
.faq-contents .faq-item.open .faq-title {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.faq-contents .faq-item.open .faq-title::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.faq-contents .faq-item.open .faq-panel .faq-para {
  color: var(--light-color);
}
.faq-contents .faq-item.active .faq-panel {
  display: block;
}
.faq-contents .faq-item:not(:first-child) {
  margin-top: 24px;
}
.faq-contents .faq-item .faq-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--heading-color);
  font-family: var(--heading-font);
  padding: 20px;
  background: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  -webkit-transition: 300ms;
  transition: 300ms;
  margin-right: 30px;
}

.faq-contents .faq-item .faq-title svg {
  font-weight: 900;
  position: absolute;
  right: -4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 300ms;
  transition: 300ms;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 21px;
    line-height: 32px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 19px;
    line-height: 32px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 575.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 17px;
  }
}
@media only screen and (max-width: 375px) {
  .faq-contents .faq-item .faq-title {
    font-size: 16px;
  }
}
.faq-contents .faq-item .faq-panel {
  padding: 20px;
  display: none;
  border-top: 1px solid var(--input-color);
}
.faq-contents .faq-item .faq-panel .faq-para {
  font-size: 16px;
  line-height: 30px;
  color: var(--light-color);
}
@media only screen and (max-width: 767.98px) {
  .faq-contents .faq-item .faq-panel .faq-para {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .faq-contents .faq-item .faq-panel .faq-para {
    font-size: 14px;
  }
}

.faq-question-border {
  border: 1px solid var(--input-color);
  padding: 20px;
}
.faq-question-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
  color: rgb(80, 45, 0);
}

.faq-question-form .single-input:not(:first-child) {
  margin-top: 20px;
}

.custom-form .single-input,
.custom-form .form-group {
  width: 100%;
}
.custom-form .single-input .label-title,
.custom-form .form-group .label-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .custom-form .single-input .label-title,
.custom-form .form-group .label-title {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .custom-form .single-input .label-title,
.custom-form .form-group .label-title {
    font-size: 14px;
  }
}
.custom-form .single-input .input-icon,
.custom-form .form-group .input-icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.custom-form .single-input .iti,
.custom-form .form-group .iti {
  width: 100%;
}
.custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
  -moz-appearance: textfield;
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.custom-form .single-input .form--control.input-padding-left,
.custom-form .single-input .form-control.input-padding-left,
.custom-form .form-group .form--control.input-padding-left,
.custom-form .form-group .form-control.input-padding-left {
  padding-left: 45px;
}
.custom-form .single-input .form--control#phone,
.custom-form .single-input .form-control#phone,
.custom-form .form-group .form--control#phone,
.custom-form .form-group .form-control#phone {
  width: 100%;
  padding-left: 50px;
}
.custom-form .single-input .form--control::-webkit-outer-spin-button, .custom-form .single-input .form--control::-webkit-inner-spin-button,
.custom-form .single-input .form-control::-webkit-outer-spin-button,
.custom-form .single-input .form-control::-webkit-inner-spin-button,
.custom-form .form-group .form--control::-webkit-outer-spin-button,
.custom-form .form-group .form--control::-webkit-inner-spin-button,
.custom-form .form-group .form-control::-webkit-outer-spin-button,
.custom-form .form-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.custom-form .single-input .form--control:focus,
.custom-form .single-input .form-control:focus,
.custom-form .form-group .form--control:focus,
.custom-form .form-group .form-control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
    font-size: 14px;
  }
}
.custom-form .single-input .form-message,
.custom-form .form-group .form-message {
  height: 100px;
  line-height: 28px;
  padding: 15px;
}
.custom-form .submit-btn {
  margin-top: 25px;
}

.submit-btn {
  border: 2px solid var(--main-color-one);
  background-color: var(--main-color-one);
  color: #fff;
  padding: 12px 20px;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.submit-btn:hover {
  background-color: unset;
  color: var(--main-color-one);
}

.checkbox-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
}
.check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0px;
  margin-top: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.check-input::after {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.6) rotate(90deg);
  transform: scale(1.6) rotate(90deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.check-input:checked {
  background: var(--main-color-one);
  border-color: var(--main-color-one);
  background: var(--main-color-one);
}
.check-input:checked::after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.2) rotate(0deg);
  transform: scale(1.2) rotate(0deg);
}
.checkbox-label {
  cursor: pointer;
  text-align: left;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: var(--heading-color);
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 575.98px) {
  .checkbox-label {
    font-size: 15px;
  }
}
.checkbox-label a {
  color: var(--main-color-one);
  font-weight: 500;
}

.pagination-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.pagination-list-item-button {
  display: inline-block;
  font-size: 16px;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  background-color: var(--input-color);
  line-height: 1;
  padding: 9px 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pagination-list-item-button:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item:hover .pagination-list-item-link {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item.active .pagination-list-item-link {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item-link {
  display: inline-block;
  font-size: 16px;
  color: var(--paragraph-color);
  border: 1px solid #fff;
  background-color: #fff;
  line-height: 1;
  padding: 9px 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 375px) {
  .center-text {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.popup-overlay.popup-active {
  visibility: visible;
  opacity: 1;
}

.popup-contents {
  max-width: 500px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  padding: 30px;
  margin: auto;
  border-radius: 10px;
}
.popup-contents-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: var(--heading-color);
  margin: -4px 0 0;
}
.popup-contents-select {
  display: flow-root;
  margin-top: 25px;
}
.popup-contents-select-label {
  font-size: 16px;
  color: var(--heading-color);
  margin-bottom: 8px;
  font-weight: 500;
}
.popup-contents-btn {
  margin-top: 25px;
}

.navbar-padding {
  padding: 20px 0;
}

.navbar-area {
  position: relative;
  z-index: 95;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area {
    padding: 0;
  }
}
.navbar-area.nav-absolute {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0px;
  z-index: 99;
}
.navbar-area.navbar-border {
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
}
.navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .track-icon-list .single-icon .icon {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .track-icon-list .single-icon.hover-color-two:hover .icon:hover {
  color: var(--main-color-two);
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .login-account .accounts {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .login-account .accounts.hover-color-two:hover {
  color: var(--main-color-two);
}
.navbar-area.navbar-two {
  padding: 0;
}
.navbar-area.navbar-two .nav-container {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 0 0 20px 20px;
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li a:hover {
  color: var(--secondary-color);
  background: none;
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  border-bottom: 5px solid var(--secondary-color);
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.navbar-area .nav-container {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 15px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container {
    padding: 20px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container {
    padding: 15px 0;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .logo-wrapper {
    height: 25px;
  }
}
.navbar-area .nav-container .logo-wrapper .logo img {
  max-width: 110px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 90px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 80px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav {
  display: block;
  width: 100%;
  text-align: center;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav.nav-margin-top {
  margin-top: 15px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav.desktop-center {
  text-align: center;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-size: 16px;
  font-family: var(--body-font);
  text-transform: capitalize;
  color: var(--heading-color);
  font-weight: 400;
  line-height: 32px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
  margin-left: 0px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 14px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
  padding: 10px 30px 10px 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 7px 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 5px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 5px 10px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a:hover {
  color: var(--main-color-one);
  background: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.current-menu-item {
  background: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  padding-right: 0;
  position: relative;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
  padding: 10px 5px 10px 0;
  position: relative;
  z-index: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
    padding: 10px 10px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a::after {
  content: "\f107";
  font-family: "Line Awesome Free";
  font-weight: 900;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  top: 1px;
  left: 4px;
  position: relative;
  display: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 992;
  border-bottom: 5px solid var(--main-color-one);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 50px;
  -webkit-column-rule-style: solid;
     -moz-column-rule-style: solid;
          column-rule-style: solid;
  -webkit-column-rule-color: rgba(221, 221, 221, 0.3);
     -moz-column-rule-color: rgba(221, 221, 221, 0.3);
          column-rule-color: rgba(221, 221, 221, 0.3);
  -webkit-column-rule-width: 2px;
     -moz-column-rule-width: 2px;
          column-rule-width: 2px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two {
    -webkit-column-count: unset;
       -moz-column-count: unset;
            column-count: unset;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two li {
  margin-bottom: -1px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two li {
    margin-bottom: 0;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: -1px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    margin-bottom: 0;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:last-child {
  margin-bottom: -1px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
  border-top: 1px solid #e2e2e2;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  display: block;
  padding: 10px 30px;
  background-color: #fff;
  white-space: nowrap;
  color: var(--heading-color);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu {
  left: 100%;
  top: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
  color: #fff;
}
.navbar-area.white-nav .navbar-right-btn a {
  color: #fff;
}
.navbar-area.navbar-two .navbar-right-btn a:hover {
  color: var(--secondary-color);
}
.navbar-area.navbar-two .btn-wrapper .cmn-btn.btn-bg-1 {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.navbar-area.navbar-two .btn-wrapper .cmn-btn.btn-bg-1:hover {
  background-color: unset;
  color: var(--secondary-color);
}

.click-nav-right-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .click-nav-right-icon {
    position: absolute;
    right: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
    display: block;
  }
  .click-nav-right-icon:hover {
    color: var(--heading-color);
  }
  .click-nav-right-icon.white-color {
    color: #fff;
  }
  .click-nav-right-icon.white-color:hover {
    color: #fff;
  }
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .show-nav-content {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    margin-top: -50px;
    margin-left: auto;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .show-nav-content.show {
    visibility: visible;
    opacity: 1;
    margin-top: 30px;
  }
}

.single-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.navbar-right-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px 20px;
}

.navbar-right-btn a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--heading-color);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.navbar-right-btn a:hover {
  color: var(--main-color-one);
  text-decoration: underline;
}

@media only screen and (max-width: 991px) {
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav {
    background-color: var(--heading-color);
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    background-color: #202020 !important;
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    background-color: transparent;
    color: #fff;
  }
  .navbar-area.white-nav .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler.active .navbar-toggler-icon::before, .navbar-area.white-nav .navbar-toggler.active .navbar-toggler-icon::after {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon::before, .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon::after {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .click-nav-right-icon {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-area .navbar-toggler {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid rgba(0, 0, 0, 0.7);
    outline: none;
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon {
    background: none;
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon::before, .navbar-area .navbar-toggler.active .navbar-toggler-icon::after {
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon {
    background: rgba(0, 0, 0, 0.7);
    display: inline-block;
    width: 25px;
    height: 2px;
    margin: 10px -4px 10px;
    position: relative;
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon::before, .navbar-area .navbar-toggler .navbar-toggler-icon::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 25px;
    background: rgba(0, 0, 0, 0.7);
    top: -7px;
    left: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon::after {
    top: auto;
    bottom: -7px;
  }
  .navbar-area .nav-container {
    position: relative;
    z-index: 0;
    min-height: 50px;
    padding: 20px 20px 30px !important;
  }
  .navbar-area .nav-container .responsive-mobile-menu {
    display: block;
    position: relative;
    width: 100%;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    display: block;
    margin-top: 30px;
    /* background: var(--gray-color); */
    /* -webkit-box-shadow: 0 0 30px rgba(221, 221, 221, 0.3); */
    /* box-shadow: 0 0 30px rgba(221, 221, 221, 0.3); */
    border-radius: 10px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    display: block;
    text-align: left;
    line-height: 30px;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 15px 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li:last-child {
    border-bottom: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    top: 25px;
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
    padding-bottom: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    height: auto;
    opacity: 1;
    background-color: transparent;
    border-bottom: none;
    padding-top: 10px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
    padding: 15px 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: initial;
    display: block;
    width: 100%;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 0;
    padding-bottom: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    max-height: 250px;
    overflow-y: scroll;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
    content: "\f107";
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li.menu-item-has-children:hover:before {
    color: #fff;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
    border-top: none;
  }
}
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 70px;
  }
}

.footer-area {
  position: relative;
  z-index: -1;
}

.footer-padding {
  padding: 25px 0;
}

.footer-widget-title {
  font-size: 28px;
  line-height: 32px;
}
@media only screen and (max-width: 767.98px) {
  .footer-widget-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.footer-widget-nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 12px 30px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .footer-widget-nav-list {
    gap: 12px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-widget-nav-list {
    gap: 12px 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-widget-nav-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 7px 20px;
  }
}
@media only screen and (max-width: 575.98px) {
  .footer-widget-nav-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 7px 15px;
  }
}
.footer-widget-nav-list-link {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  color: var(--light-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-nav-list-link:hover {
  color: var(--main-color-one);
  text-decoration: underline;
}
.footer-widget-social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 12px 20px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .footer-widget-social-list {
    gap: 12px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-widget-social-list {
    gap: 10px;
  }
}
.footer-widget-social-list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 35px;
  width: 35px;
  font-size: 18px;
  border-radius: 5px;
  background: #fff;
  color: var(--heading-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
@media only screen and (max-width: 575.98px) {
  .footer-widget-social-list-link {
    font-size: 16px;
    height: 35px;
    width: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .footer-widget-social-list-link {
    height: 30px;
    width: 30px;
  }
}
.footer-widget-social-list-link:hover {
  color: #fff;
  background: var(--main-color-one);
}
.footer-widget-form-single {
  position: relative;
  z-index: 2;
}
.footer-widget-form-control {
  height: 55px;
  width: 100%;
  background-color: var(--copyright-bg-1);
  border: 1px solid var(--copyright-bg-1);
  border-radius: 30px;
  padding: 0 20px;
  color: var(--light-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.001);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.001);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-form-control:focus {
  border: 1px solid rgba(var(--main-color-one-rgb), 0.4);
}
.footer-widget-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0;
  background-color: var(--main-color-one);
  color: #fff;
  padding: 0px 30px;
  border: 0;
  outline: none;
  border-radius: 30px;
  height: calc(100% - 10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-form button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.footer-navbar {
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-wrapper-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 48px 0px;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 100px 0;
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-flex {
    padding: 70px 0;
  }
}
.footer-wrapper-single:not(:last-child) {
  padding-right: 100px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 50px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 0px;
  }
}
.footer-wrapper-single:not(:first-child) {
  padding-left: 100px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-wrapper-single:not(:first-child) {
    padding-left: 50px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .footer-wrapper-single:not(:first-child) {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-single:not(:first-child) {
    border-left: unset;
    padding-left: 0;
  }
}

.copyright-area.copyright-border {
  border-top: 1px solid rgba(221, 221, 221, 0.2);
}
.copyright-area.copyright-border.white-color {
  border-color: rgba(221, 221, 221, 0.7);
}

.copyright-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
@media only screen and (max-width: 767.98px) {
  .copyright-contents-flex {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.copyright-contents-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 15px 50px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.copyright-contents-single:hover .copyright-contents-single-icon {
  color: var(--main-color-one);
}
.copyright-contents-single-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.copyright-contents-single-icon {
  font-size: 40px;
  color: var(--light-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright-contents-single-details-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: block;
  color: var(--light-color);
}
.copyright-contents-single-details-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin-top: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright-contents-single-details-link:hover {
  color: var(--main-color-one);
}
.copyright-contents-main {
  font-size: 16px;
  color: var(--light-color);
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  .copyright-contents-main {
    text-align: center;
    margin-top: 10px;
  }
}
.copyright-contents-main a {
  color: var(--main-color-one);
  font-weight: 500;
}

.footer-area-two .footer-widget-contents-para {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-form-control {
  background-color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.001);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.001);
  padding-right: 130px;
}
.footer-area-two .footer-widget-form-control:focus {
  border: 1px solid rgba(var(--secondary-color-rgb), 0.8);
}
.footer-area-two .footer-widget-form button {
  background-color: var(--secondary-color);
}
.footer-area-two .footer-widget-form button:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.7);
  color: #fff;
}
.footer-area-two .footer-widget-para {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-nav-list-link {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-nav-list-link:hover {
  color: var(--secondary-color);
}
.footer-area-two .footer-widget-social-list-link:hover {
  background-color: var(--secondary-color);
}
.footer-area-two .copyright-contents-single:hover .copyright-contents-single-icon {
  color: #fff;
  background-color: var(--secondary-color);
}
.footer-area-two .copyright-contents-single-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 30px;
  background-color: #fff;
  color: var(--secondary-color);
  border-radius: 50%;
}
.footer-area-two .copyright-contents-single-details-title {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .copyright-contents-single-details-link:hover {
  color: var(--secondary-color);
}
.footer-area-two .copyright-contents-main {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .copyright-contents-main a {
  color: var(--secondary-color);
}

.banner-area {
  position: relative;
  z-index: 1;
  padding: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-area {
    padding-top: 0 !important;
  }
}
.banner-area-one {
  padding-top: 50px;
}

.banner-single {
  position: relative;
  z-index: 2;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single {
    padding-right: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single {
    padding-right: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single {
    padding-right: 30px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single {
    padding-right: 0px;
  }
}
.banner-single-one.percent-padding {
  padding-left: 15%;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single-one.percent-padding {
    padding-left: 13%;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single-one.percent-padding {
    padding-left: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-one.percent-padding {
    padding-left: 5%;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-one.percent-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-single-one.percent-padding {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-one.percent-padding {
    padding-left: 10px;
  }
}
.banner-single-content {
  padding: 50px 0;
  position: relative;
  z-index: 5;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content {
    padding: 30px 0 0px 0px;
  }
}
.banner-single-content.text-white .banner-single-content-title {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-para {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-reviews-para {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-reviews-para span {
  color: #fff;
}
.banner-single-content-reviews {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.banner-single-content-reviews-icon {
  color: var(--secondary-color);
}
.banner-single-content-reviews-para {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  color: var(--paragraph-color);
}
.banner-single-content-reviews-para span {
  display: inline-block;
}
.banner-single-content-title {
  font-size: 60px;
  line-height: 74px;
  margin: -30px 0 45px;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single-content-title {
    font-size: 50px;
    line-height: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single-content-title {
    font-size: 48px;
    line-height: 65px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-content-title {
    font-size: 42px;
    line-height: 55px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content-title {
    font-size: 32px;
    line-height: 1.2;
    margin: 20px 0;
    text-align: center;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-single-content-title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-title {
    font-size: 26px;
  }
}
.banner-single-content-title:hover {
  color: var(--main-color-one);
}
.banner-single-content-title-shape {
  position: relative;
  z-index: 1;
}
.banner-single-content-title-shape::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../img/section-shapes2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-content-title-shape::before {
    top: 30px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content-title-shape::before {
    top: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-single-content-title-shape::before {
    top: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-title-shape::before {
    top: 15px;
  }
}
.banner-single-content-title-shape.star-shape {
  position: relative;
  z-index: 2;
}
.banner-single-content-title-shape.star-shape::before {
  background-image: url(../img/banner/star-shape.svg);
  top: 20px;
  left: auto;
  right: -40%;
}
.banner-single-content-para {
  font-size: 16px;
  color: var(--light-color);
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 480px) {
  .banner-single-content-para {
    font-size: 16px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-para {
    font-size: 15px;
  }
}

.banner-right-bg {
  height: 670px;
  width: 47%;
  text-align: right;
  margin-left: auto;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-right-bg {
    width: 100%;
    height: 500px;
  }
}
@media only screen and (max-width: 767.98px) {
  .banner-right-bg {
    height: 400px;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-right-bg {
    height: 300px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-right-bg {
    height: 260px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-right-bg {
    height: 220px;
  }
}

.banner-area-two {
  padding-top: 140px;
}

.banner-shapes img {
  position: absolute;
}
.banner-shapes img:nth-child(1) {
  left: 10px;
  top: 0;
}
.banner-shapes img:nth-child(2) {
  right: 10px;
  top: 0;
}

.banner-bg {
  background: var(--gradient-circle);
}

.banner-area-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 25px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-area-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.banner-area-flex .banner-single-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.banner-thumb-wrap {
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-area-thumb {
    max-width: 500px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-area-thumb {
    max-width: 400px;
  }
}

.banner-thumb-shape img {
  position: absolute;
}
.banner-thumb-shape img:nth-child(1) {
  right: 0;
  top: 0;
}
.banner-thumb-shape img:nth-child(2) {
  right: -100px;
  bottom: 130px;
}
.banner-thumb-shape img:nth-child(3) {
  left: -50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.location-area {
  margin-top: 14px !important;
  z-index: 9;
  position: relative;
}

.banner-location {
  padding: 20px;
}

@media (max-width: 640px) {
  .banner-location {
    padding: 17px 5px;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .banner-location {
    padding: 20px 10px;
  }
}

@media (min-width: 1025px) {
  .banner-location {
    padding: 20px;
  }
}

.banner-location-one {
  width: 100%;
  position: relative;
  z-index: 990;
  background-color: white !important;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-one {
    width: 100%;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-one {
    width: 100%;
  }
}
.banner-location-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 10px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.banner-location-single {
  width: 100% !important;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
  }
}
@media only screen and (max-width: 767.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-location-single:nth-last-of-type(1), .banner-location-single:nth-last-of-type(2) {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
  }
}

.banner-location-single-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  align-items: flex-end;
}
.banner-location-single-icon {
  font-size: 28px;
}
.banner-location-single-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.banner-location-single-contents-dropdown {
  font-family: var(--heading-font);
  font-weight: 500;
}
.banner-location-single-contents-dropdown .js-select {
  font-weight: 500;
}
.banner-location-single-contents-subtitle {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: -4px 0 0;
  margin-bottom: 10px;
  font-family: var(--heading-font);
}
.search-suggestions-wrapper {
  position: relative;
  z-index: 1;
}
.search-suggestions-wrapper .search-click-icon {
  cursor: pointer;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 55px;
  width: 55px;
  color: #fff;
  background-color: var(--main-color-one);
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  border-radius: 5px;
}
.search-suggestions-wrapper .search-click-icon:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.8);
  color: #fff;
}

.search-show {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-show {
    right: 0px;
    max-width: 500px;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-show {
    width: 500px;
  }
}
@media only screen and (max-width: 575.98px) {
  .search-show {
    max-width: 450px;
  }
}
@media only screen and (max-width: 480px) {
  .search-show {
    max-width: 340px;
  }
}
@media only screen and (max-width: 375px) {
  .search-show {
    max-width: 270px;
  }
}
.search-show.open {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.search-show-inner {
  position: relative;
}
.search-show-form {
  position: relative;
  z-index: 1;
}
.search-show-form .suggestions-icon-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: 0;
  font-size: 24px;
  background-color: rgba(221, 221, 221, 0.3);
  height: 100%;
  padding: 0 15px;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.search-show-form .suggestions-icon-close:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.search-show-form .form--control {
  padding: 0 15px;
  padding-left: 50px;
  padding-right: 70px;
  font-size: 15px;
  height: 55px;
  width: 700px;
  color: var(--extra-light-color);
  border: 1px solid rgba(221, 221, 221, 0.5);
  -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.01);
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.01);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.search-show-form .form--control:focus {
  -webkit-box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.1);
  border-color: rgba(var(--main-color-one-rgb), 0.3);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-show-form .form--control {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-show-form .form--control {
    width: 100%;
  }
}
.search-show-form button {
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 20px;
  background: none;
  border: 0;
  outline: none;
  color: var(--light-color);
  bottom: 0;
}

.search-product {
  position: absolute;
  top: 100%;
  right: 0;
  width: 700px;
  background: #fff;
  padding: 30px 20px;
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.4);
          box-shadow: 0 0 10px rgba(221, 221, 221, 0.4);
  visibility: hidden;
  opacity: 0;
  height: 550px;
  overflow-y: hidden;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product {
    height: 475px;
    padding: 30px 10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-product {
    height: 485px;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-product {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .search-product {
    padding: 30px 10px;
  }
}
.search-product.show {
  visibility: visible;
  opacity: 1;
}
.search-product .nicescroll-rails .nicescroll-cursors {
  background-color: var(--main-color-two) !important;
}
.search-product-title {
  font-size: 18px;
  width: 100%;
  background-color: rgba(221, 221, 221, 0.2);
  padding: 5px 10px;
}
.search-product-list {
  height: calc(100vh - 210px);
  overflow-y: scroll;
  padding: 0 10px;
}
.search-product-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.search-product-list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
@media only screen and (max-width: 480px) {
  .search-product-list-link {
    display: block;
  }
}
.search-product-list-image {
  border: 1px solid rgba(221, 221, 221, 0.5);
  padding: 5px;
  height: 100px;
  width: 100px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-image {
    width: 80px;
    height: 100px;
    padding: 10px 0px;
  }
}
.search-product-list-info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 480px) {
  .search-product-list-info-top {
    margin-top: 10px;
  }
}
.search-product-list-info-title {
  font-size: 18px;
  line-height: 28px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-title {
    font-size: 17px;
  }
}
.search-product-list-info-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price {
    gap: 7px;
  }
}
.search-product-list-info-price-through {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.search-product-list-info-price-through .flash-price {
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-color);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price-through .flash-price {
    font-size: 16px;
  }
}
.search-product-list-info-price-through .old-price {
  font-size: 14px;
  text-decoration: line-through;
}
.search-product-list-info-price .main-price {
  font-size: 16px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price .main-price {
    font-size: 17px;
  }
}
.search-product-list-info .stock-out {
  font-size: 16px;
  background: #ff0000;
  color: #fff;
  padding: 5px 10px;
  display: inline-block;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info .stock-out {
    font-size: 15px;
    padding: 5px 5px;
  }
}

.search-suggestion-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 92;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 500ms;
  transition: 500ms;
}
.search-suggestion-overlay.show {
  visibility: visible;
  opacity: 1;
}

.single-why {
  padding: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-why {
    padding: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-why {
    padding: 20px;
  }
}
.single-why:hover {
  border-color: var(--main-color-one);
}
.single-why-border {
  border: 2px solid var(--gray-color);
}
.single-why-icon img {
  max-width: 60px;
  margin: auto;
}
.single-why-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 15px;
}
.single-why-contents-title {
  font-size: 20px;
  line-height: 28px;
  margin: -4px 0 0;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-contents-title:hover {
  color: var(--main-color-one);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-why-contents-title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575.98px) {
  .single-why-contents-title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 480px) {
  .single-why-contents-title {
    font-size: 24px;
  }
}
.single-why-contents-para {
  font-size: 16px;
  line-height: 26px;
  color: var(--paragraph-color);
}

.single-why-two {
  padding: 20px;
  background-color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  z-index: 1;
}
.single-why-two::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 0%;
  width: 0%;
  z-index: -1;
  background-color: var(--main-color-one);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-two:hover {
  background-color: var(--main-color-one);
  border-color: var(--main-color-one);
  color: #fff;
}
.single-why-two:hover::before {
  background-color: var(--main-color-one);
  visibility: visible;
  opacity: 1;
  height: 100%;
  width: 100%;
}
.single-why-two:hover .single-why-two-icon {
  background-color: #fff;
  color: var(--main-color-one);
}
.single-why-two:hover .single-why-two-contents-title {
  color: #fff;
}
.single-why-two:hover .single-why-two-contents-para {
  color: #fff;
}
.single-why-two-border {
  border: 2px solid var(--gray-color);
}
.single-why-two-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 15px;
}
@media only screen and (max-width: 375px) {
  .single-why-two-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.single-why-two-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 24px;
  background-color: rgba(var(--main-color-one-rgb), 0.2);
  color: var(--main-color-one);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-two-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-why-two-contents-title {
  font-size: 16px;
  line-height: 28px;
  margin: -4px 0 0;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-two-contents-title:hover {
  color: var(--main-color-one);
}
.single-why-two-contents-para {
  font-size: 15px;
  line-height: 24px;
  color: var(--paragraph-color);
}

div[class*=col]:nth-child(4n+3) .single-why-two-icon {
  background-color: rgba(var(--secondary-color-rgb), 0.2);
  color: var(--secondary-color);
}
div[class*=col]:nth-child(4n+3) .single-why-two:hover {
  border-color: var(--secondary-color);
}
div[class*=col]:nth-child(4n+3) .single-why-two:hover::before {
  background-color: var(--secondary-color);
}
div[class*=col]:nth-child(4n+3) .single-why-two:hover .single-why-two-icon {
  background-color: #fff;
  color: var(--secondary-color);
}
div[class*=col]:nth-child(4n+3) .single-why-two:hover .single-why-two-contents-title {
  color: #fff;
}
div[class*=col]:nth-child(4n+3) .single-why-two:hover .single-why-two-contents-para {
  color: #fff;
}

div[class*=col]:nth-child(4n+4) .single-why-two::before {
  background-color: #019267;
}
div[class*=col]:nth-child(4n+4) .single-why-two-icon {
  background-color: rgba(1, 146, 103, 0.2);
  color: #019267;
}
div[class*=col]:nth-child(4n+4) .single-why-two:hover {
  border-color: #019267;
}
div[class*=col]:nth-child(4n+4) .single-why-two:hover .single-why-two-icon {
  background-color: #fff;
  color: #019267;
}
div[class*=col]:nth-child(4n+4) .single-why-two:hover .single-why-two-contents-title {
  color: #fff;
}
div[class*=col]:nth-child(4n+4) .single-why-two:hover .single-why-two-contents-para {
  color: #fff;
}

.guest-wrapper {
  position: relative;
  z-index: 2;
}
.guest-wrapper-shapes img {
  position: absolute;
  top: 0%;
  left: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .guest-wrapper-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .guest-wrapper-images {
    gap: 10px;
  }
}
.guest-wrapper-images-single {
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
  border-radius: 50%;
  max-width: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .guest-wrapper-images-single {
    position: unset;
  }
}
.guest-wrapper-images-single.active {
  opacity: 1;
  -webkit-box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.3);
  box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.3);
  border: 4px solid #fff;
}
.guest-wrapper-images-single:nth-child(1) {
  left: 0;
  top: 0;
}
.guest-wrapper-images-single:nth-child(2) {
  right: 0;
  top: 0;
  max-width: 85px;
}
.guest-wrapper-images-single:nth-child(3) {
  left: 150px;
  top: 45%;
  max-width: 75px;
}
.guest-wrapper-images-single:nth-child(4) {
  right: 150px;
  top: 45%;
  max-width: 75px;
}
.guest-wrapper-images-single:nth-child(5) {
  left: 0;
  bottom: 0;
  max-width: 65px;
}
.guest-wrapper-images-single:nth-child(6) {
  right: 0;
  bottom: 0;
  max-width: 65px;
}
.guest-wrapper-images-single img {
  border-radius: 100%;
  object-fit: cover;
}

.tab-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-100%) scale(0);
          transform: translateY(-100%) scale(0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 0;
}

.tab-area .tab-item.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0%) scale(1);
          transform: translateY(0%) scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 100%;
}

@-webkit-keyframes slideAnimation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideAnimation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.single-guest.center-text .single-guest-thumb {
  margin: auto;
}
.single-guest-thumb {
  max-width: 100px;
}
.single-guest-thumb img {
  border-radius: 50%;
}

.single-guest-two {
  padding: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-guest-two-border {
  border: 2px solid var(--gray-color);
}
.single-guest-two:hover {
  border-color: var(--secondary-color);
}
.single-guest-two-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.single-guest-two-thumb {
  max-width: 60px;
}
.single-guest-two-thumb img {
  border-radius: 50%;
}
.single-guest-two-contents-title {
  font-size: 18px;
  font-weight: 600;
}
.single-guest-two-contents-country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  margin-top: 7px;
}
.single-guest-two-contents-country-name {
  font-size: 16px;
  line-height: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-guest-two-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
}

.login-padding {
  padding: 30px;
}

@media only screen and (max-width: 480px) {
  .login-padding {
    padding: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .login-padding {
    padding: 20px 10px;
  }
}

.login-wrapper-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 25px 50px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .login-wrapper-flex {
    gap: 25px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-wrapper-flex {
    gap: 25px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.login-wrapper-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 1000px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .login-wrapper-contents {
    max-width: 800px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-wrapper-contents {
    max-width: 650px;
  }
}
.input-flex-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (max-width: 480px) {
  .input-flex-item {
    display: block;
  }
}
.input-flex-item .single-input {
  width: 100%;
}
.input-flex-item .single-input:not(:first-child) {
  margin-left: 24px;
}
@media only screen and (max-width: 480px) {
  .input-flex-item .single-input:not(:first-child) {
    margin-left: 0;
  }
}
.input-flex-item .single-input .form--control {
  border: 1px solid rgba(221, 221, 221, 0.4);
}

.single-input {
  display: inline-block;
  width: 100%;
}
.single-input .form--control {
  border: 1px solid rgba(221, 221, 221, 0.4);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 0 10px transparent;
          box-shadow: 0 0 10px transparent;
}
.single-input .form--control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
          box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
.single-input .form--message {
  padding: 15px 0 0 15px;
}

.label-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .label-title {
    font-size: 15px;
  }
}

.or-contents {
  text-align: center;
  position: relative;
  z-index: 2;
}
.or-contents::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background: rgba(221, 221, 221, 0.4);
  width: 100%;
  height: 1px;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.or-contents-para {
  background: #fff;
  padding: 10px 30px;
  color: var(--light-color);
}

.single-input {
  position: relative;
}

.toggle-password {
  position: absolute;
  bottom: 13px;
  right: 20px;
  cursor: pointer;
}

.toggle-password.show-pass .hide-icon {
  display: block;
}

.toggle-password.show-pass .show-icon {
  display: none;
}

.single-blog {
  border-radius: 10px;
  overflow: hidden;
}
.single-blog:hover .single-blog-thumbs img {
  -webkit-transform: scaleY(1.2) rotate(5deg);
  transform: scaleY(1.2) rotate(5deg);
}
.single-blog.blog-two .single-blog-thumbs-date {
  background-color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item i {
  color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item a:hover {
  color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item:not(:first-child)::before {
  background-color: var(--secondary-color);
}

.single-shop-left {
  padding: 25px 25px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-shop-left {
    padding: 22px 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
.single-shop-left-title.open .title::after {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.single-shop-left-title .title {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  line-height: 36px;
  margin: -4px 0 0;
}
.single-shop-left-title .title::after {
  content: "\f106";
  content: "\f106";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 16px;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.single-shop-left-filter-list {
  padding: 0;
}
.single-shop-left-filter-list .item {
  position: relative;
  z-index: 2;
  display: block;
  padding-left: 30px;
  /* margin-top: 10px; */
  line-height: 30px;
}
.single-shop-left-filter-list .item.active::before {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f00c";
  background: var(--main-color-one);
  border-color: var(--main-color-one);
}
.single-shop-left-filter-list .item a {
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary-color);
}
.single-shop-left-filter-list .item::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(221, 221, 221, 0.4);
  left: 0;
  top: 4px;
  background: none;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.hotel-view {
  overflow: hidden;
}
.hotel-view:hover .hotel-view-list-thumb::before, .hotel-view:hover .hotel-view-list-thumb::after {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.hotel-view:hover .hotel-view-list-thumb::after {
  left: auto;
  right: 100%;
}
.hotel-view-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-flex {
    display: -ms-grid;
    display: grid;
  }
}
.hotel-view-list-thumb {
  height: 326px;
  width: 300px;
  background-size: contain;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hotel-view-list-thumb {
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-list-thumb {
    width: 250px;
    height: 435px;
    background-size: cover;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .hotel-view-list-thumb {
    width: 250px;
    height: 360px;
    background-size: cover;
  }
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-list-thumb {
    background-size: contain;
    margin: 0px auto 0;
    width: 100%;
    height: 561px;
  }
}
@media only screen and (max-width: 575.98px) {
  .hotel-view-list-thumb {
    height: 497px;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-list-thumb {
    min-height: 250px;
    height: 382px;
  }
}
@media only screen and (max-width: 375px) {
  .hotel-view-list-thumb {
    min-height: 250px;
    height: 322px;
  }
}
.hotel-view-grid-thumb {
  height: 215px;
  width: 100%;
  background-size: contain;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .hotel-view-grid-thumb {
    height: 213px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hotel-view-grid-thumb {
    height: 178px;
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-grid-thumb {
    height: 148px;
    background-size: cover;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .hotel-view-grid-thumb {
    height: 157px;
  }
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-grid-thumb {
    height: 240px;
  }
}
@media only screen and (max-width: 575.98px) {
  .hotel-view-grid-thumb {
    height: 257px;
    background-size: cover;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-grid-thumb {
    height: 200px;
    background-size: cover;
  }
}
@media only screen and (max-width: 375px) {
  .hotel-view-grid-thumb {
    height: 170px;
    background-size: cover;
  }
}
.hotel-view-thumb {
  overflow: hidden;
}
.hotel-view-thumb:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.hotel-view-thumb img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.hotel-view-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.hotel-view-contents-header {
  padding: 25px 20px 10px;
}
.hotel-view-contents-review {
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  padding: 5px 15px;
  border-radius: 5px;
  color: var(--secondary-color);
}
.hotel-view-contents-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 10px;
}
.hotel-view-contents-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
}
.hotel-view-contents-location-icon {
  font-size: 20px;
  display: inline-block;
}
.hotel-view-contents-location-para {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.hotel-view-contents-middle {
  padding: 10px 20px;
  border-top: 1px solid var(--input-color);
  border-bottom: 1px solid var(--input-color);
}
.hotel-view-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-contents-flex {
    gap: 5px 15px;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-contents-flex {
    gap: 5px 15px;
  }
}
.hotel-view-contents-icon {
  font-size: 20px;
  color: var(--light-color);
}
.hotel-view-contents-icon-title {
  font-size: 15px;
  line-height: 28px;
}
.hotel-view-contents-icon-more {
  color: var(--main-color-one);
  color: var(--main-color-one);
  text-decoration: underline;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.hotel-view-contents-icon-more:hover {
  color: var(--main-color-one);
}
.hotel-view-contents-bottom {
  padding: 0 20px 25px;
}
.hotel-view-contents-bottom-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.hotel-view-contents-bottom-title {
  color: var(--main-color-one);
  font-size: 24px;
  font-weight: 600;
}
.hotel-view-contents-bottom-title sub {
  font-size: 20px;
  font-weight: 500;
}
.hotel-view-contents-bottom-para {
  font-size: 16px;
  margin-top: 10px;
}

.dashboard-list .list.active > a .badge-notification {
  background-color: #fff;
  color: var(--main-color-one);
}

.badge-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 10px;
  height: 22px;
  width: 22px;
  background: var(--main-color-one);
  color: #fff;
  border-radius: 3px;
}

.dashboard-padding {
  padding: 50px;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .dashboard-padding {
    padding: 40px;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .dashboard-padding {
    padding: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .dashboard-padding {
    padding: 20px 5px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-padding {
    padding: 20px 5px;
  }
}

.dashboard-contents-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 35px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-contents-wrapper {
    gap: 24px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-contents-wrapper {
    gap: 24px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-contents-wrapper {
    display: block;
  }
}

.dashboard-left-content {
  background: #fff;
  text-align: center;
  height: 100%;
  padding: 0;
  border-radius: 20px;
  display: block;
  padding: 40px 20px;
  width: 335px;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .dashboard-left-content {
    height: calc(100vh - 80px);
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .dashboard-left-content {
    height: calc(100vh - 60px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-left-content {
    height: calc(100vh - 40px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-left-content {
    height: calc(100vh - 40px);
    padding: 30px 10px;
    width: 300px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content {
    height: 100vh;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-left-content {
    max-width: 280px;
  }
}

.dashboard-list .list.active > a {
  background: var(--main-color-one);
  font-weight: 500;
  color: #fff;
}
.dashboard-list .list.has-children.open.show .submenu {
  display: block;
}
.dashboard-list .list.has-children.open > a::after {
  content: none !important; 
}
.dashboard-list .list.has-children > a {
  position: relative;
}
.dashboard-list .list.has-children > a::after {
  content: none !important; 
  font-family: "Line Awesome Free";
  font-weight: 900;
  position: absolute;
  right: 15px;
  font-size: 18px;
}
.dashboard-list .list.show > .submenu {
  display: block;
}
.dashboard-list .list a {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  font-family: var(--rubik-font);
  gap: 15px;
  padding: 14px 15px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dashboard-list .list div {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  font-family: var(--rubik-font);
  gap: 15px;
  padding: 14px 15px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dashboard-list .list > .submenu {
  padding-left: 40px;
  display: none;
}
.dashboard-list .list > .submenu .list.has-children > a {
  font-size: 16px;
}
.dashboard-list .list > .submenu .list.selected a {
  color: var(--main-color-one);
}
.dashboard-list .list > .submenu .list.selected a::before {
  background: var(--main-color-one);
}
.dashboard-list .list > .submenu .list a {
  font-size: 14px;
  line-height: 22px;
  padding: 3px 0 3px 15px;
  position: relative;
  font-weight: 400;
}

.dashboard-list .list > .submenu .list a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 6px;
  width: 6px;
  background: #999;
  border-radius: 50%;
}

.dashboard-right-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.orders-child:nth-child(4n+1) .single-order {
  background: #FF8D29;
}

.orders-child:nth-child(4n+3) .single-order {
  background: #FC4F4F;
}

.orders-child:nth-child(4n+4) .single-order {
  background: #36AE7C;
}

.dashboard-promo {
  margin-bottom: 25px;
}
.dashboard-promo {
  /* Ensures flexbox layout for the row */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.orders-child {
  display: flex; /* Make the column a flex container */
  flex-direction: column; /* Stack children vertically */
}

.single-order {
  display: flex; /* Make the order a flex container */
  flex: 1; /* Allow the card to grow and fill space */
  flex-direction: column; /* Stack contents vertically */
  justify-content: space-between; /* Distribute space between children */
  height: 100%; /* Allow it to fill the height of its parent */
  border: 1px solid #ddd; /* Optional: Add border for better visibility */
  padding: 20px; /* Optional: Add padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for aesthetics */
}


.single-order {
  background: var(--main-color-one);
  padding: 35px 30px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.single-order.style-padding {
  padding: 29px 25px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order {
    padding: 20px 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-order {
    padding: 20px 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order {
    padding: 25px 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order {
    padding: 20px 15px;
  }
}
.single-order-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order-flex {
    gap: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
.single-order-icon {
  font-size: 120px;
  color: #fff;
  position: absolute;
  bottom: -30px;
  right: -30px;
  color: #fff;
  opacity: 0.2;
}
.single-order-contents-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
  margin-top: 20px;
  word-break: break-all;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order-contents-title {
    font-size: 34px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order-contents-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order-contents-title {
    font-size: 28px;
  }
}
.single-order-contents-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
  font-family: var(--heading-font);
}
@media only screen and (max-width: 767.98px) {
  .single-order-contents-subtitle {
    font-size: 15px;
  }
}

.base-padding {
  padding: 20px;
}

.dash-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 20px;
  background-color: #f3f3f3;
  color: var(--heading-color);
  border-radius: 7px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dash-btn:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-bg-1 {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-bg-1:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.dash-btn.btn-pending {
  background-color: #FF8D29;
  color: #fff;
}
.dash-btn.btn-pending:hover {
  background-color: var(--main-color-one);
}
.dash-btn.btn-cancelled {
  background-color: #FC4F4F;
  color: #fff;
}
.dash-btn.btn-cancelled:hover {
  background-color: var(--main-color-one);
}
.dash-btn.btn-accepted {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-accepted:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.dash-btn.btn-completed {
  background-color: #36AE7C;
  color: #fff;
}
.dash-btn.btn-completed:hover {
  background-color: var(--main-color-one);
}

.popup-contents-select .form--message {
  border-radius: 10px;
}

.single-reservation {
  border-radius: 20px;
  position: relative;
}
.single-reservation:not(:first-child) {
  margin-top: 25px;
}
.single-reservation-expandIcon {
  position: absolute;
  right: 20px;
  top: 35px;
  cursor: pointer;
  background: var(--input-color);
  color: var(--heading-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-reservation-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: -4px 0 0;
}
.single-reservation.open .single-reservation-expandIcon {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.single-reservation.show .single-reservation-inner {
  display: block;
}
.single-reservation-inner {
  margin-top: 18px;
  border-top: 2px solid #f3f3f3;
  padding-top: 18px;
  display: none;
}
.single-reservation-inner.show {
  display: block;
}
.single-reservation-head {
  padding-right: 40px;
  -webkit-transition: 5s;
  transition: 5s;
}
.single-reservation-item:not(:first-child) {
  padding-top: 18px;
  border-top: 2px solid #f3f3f3;
}
.single-reservation-item:not(:last-child) {
  padding-bottom: 5px;
  padding-bottom: 18px;
}
.single-reservation-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 10px;
}
.single-reservation-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-content-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.single-reservation-content-id {
  color: var(--main-color-one);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
.single-reservation-content-price {
  font-size: 24px;
  font-weight: 600;
  color: var(--main-color-one);
  margin-top: 10px;
}
.single-reservation-name-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-name-title {
  font-size: 24px;
  line-height: 28px;
  margin: -4px 0 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@media only screen and (max-width: 480px) {
  .single-reservation-name-title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .single-reservation-name-title {
    font-size: 18px;
  }
}
.single-reservation-name-para {
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
@media only screen and (max-width: 480px) {
  .single-reservation-name-para {
    font-size: 15px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .single-reservation-name-para {
    font-size: 14px;
  }
}
.single-reservation-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 24px 0;
}
.single-reservation-details-item {
  display: inline-block;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.single-reservation-details-item:not(:first-child) {
  padding-left: 20px;
  border-left: 2px solid #f3f3f3;
}
@media only screen and (max-width: 480px) {
  .single-reservation-details-item:not(:first-child) {
    padding: 0;
    border: none;
  }
}
.single-reservation-details-item:not(:last-child) {
  padding-right: 20px;
}
@media only screen and (max-width: 480px) {
  .single-reservation-details-item:not(:last-child) {
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .single-reservation-details-item {
    width: 100%;
  }
}
.single-reservation-details-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-bottom: 10px;
}
.single-reservation-details-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-color);
  line-height: 24px;
}
.single-reservation-logoPrice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.single-reservation-logoPrice-thumb {
  max-width: 50px;
}
.single-reservation-logoPrice-code {
  font-size: 16px;
  font-weight: 700;
  color: var(--heading-color);
}
.single-reservation-request-list-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 20px;
  background-color: #fff;
  padding-bottom: 50px;
}
@media only screen and (max-width: 375px) {
  .single-reservation-request-list-item {
    gap: 10px;
  }
}
.single-reservation-request-list-item.success::before {
  background-color: #65C18C;
}
.single-reservation-request-list-item::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  top: 35px;
  left: 17px;
  background: #f3f3f3;
  z-index: 0;
}
.single-reservation-request-list-item:last-child {
  padding-bottom: 0;
}
.single-reservation-request-list-item:last-child::before {
  display: none;
}
.single-reservation-request-list-item.success .single-reservation-request-list-item-number {
  border-color: #65C18C;
  color: #fff;
}
.single-reservation-request-list-item.success .single-reservation-request-list-item-number::before {
  background-color: #65C18C;
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
}
.single-reservation-request-list-item.current .single-reservation-request-list-item-number {
  border-color: var(--main-color-one);
  color: #fff;
}
.single-reservation-request-list-item.current .single-reservation-request-list-item-number::before {
  background-color: var(--main-color-one);
}
.single-reservation-request-list-item-number {
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #f3f3f3;
  background-color: unset;
}
.single-reservation-request-list-item-number::before {
  content: "";
  position: absolute;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  top: 5px;
  left: 5px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
.single-reservation-request-list-item-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-author-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 20px;
}
@media only screen and (max-width: 375px) {
  .single-reservation-author-flex {
    display: -ms-grid;
    display: grid;
  }
}
.single-reservation-author-active {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph-color);
}
.single-reservation-author-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-author-contents-subtitle {
  font-size: 16px;
  color: var(--main-color-one);
  margin-bottom: 10px;
}
.single-reservation-author-contents-title {
  font-size: 20px;
  font-weight: 600;
}
.single-reservation-author-thumb {
  max-width: 60px;
}
.single-reservation-author-thumb img {
  border-radius: 50%;
}
.single-reservation-author-footer {
  margin-top: 30px;
}
.single-reservation-author-footer-bottom {
  margin-top: 30px;
}
.single-reservation-author-para {
  font-size: 16px;
  line-height: 26px;
}
.single-reservation-author-para:not(:first-child) {
  padding-top: 25px;
}
.single-reservation-author-luggages {
  margin-bottom: 25px;
}
.single-reservation-author-luggages-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-author-luggages-thumb {
  max-width: 80px;
}
.single-reservation-tags-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-tags-item {
  padding: 10px 20px;
  display: inline-block;
  font-size: 16px;
  background-color: rgba(84, 22, 144, 0.1);
  color: #541690;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-tags-item:hover {
  background-color: #541690;
  color: #fff;
}
.single-reservation-tags-item.lost {
  background-color: rgba(235, 83, 83, 0.1);
  color: #EB5353;
}
.single-reservation-tags-item.lost:hover {
  background-color: #EB5353;
  color: #fff;
}
.single-reservation-tags-item.hotel {
  background-color: rgba(101, 193, 140, 0.1);
  color: #65C18C;
}
.single-reservation-tags-item.hotel:hover {
  background-color: #65C18C;
  color: #fff;
}
.single-reservation-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
.single-reservation-attachment-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-attachment-list-item:hover {
  color: var(--main-color-one);
}
.single-reservation-attachment-list-item-icon {
  font-size: 18px;
  color: var(--main-color-one);
}
.single-reservation-attachment-list-item-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph-color);
}
.single-reservation-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 0px;
}
.single-reservation-contact-item {
  flex-basis: 100%; /* Allow each item to take full width on small screens */
  max-width: 100%; /* Ensure no item exceeds the container's width */
  flex-shrink: 1; /* Allow items to shrink if necessary */
}
@media only screen and (min-width: 991px) {
  .single-reservation-contact-item {
    flex-basis: 48%; /* Two items per row on larger screens */
    max-width: 48%; /* Prevent items from taking up too much space */
  }
}
/* .single-reservation-contact-item:not(:first-child) {
  padding-left: 30px;
  border-left: 2px solid #f3f3f3;
} */
@media only screen and (max-width: 480px) {
  .single-reservation-contact-item:not(:first-child) {
    padding: 0;
    border: none;
  }
}
.single-reservation-contact-item:not(:last-child) {
  padding-right: 30px;
}
@media only screen and (max-width: 480px) {
  .single-reservation-contact-item:not(:last-child) {
    padding-right: 0;
  }
}
.single-reservation-contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-contact-list:hover {
  color: var(--main-color-one);
}

.dashboard-checkbox-wrapper {
  margin-top: 30px;
}

.dashboard-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
}
.dashboard-checkbox.check-success .check-input {
  border-radius: 50%;
}
.dashboard-checkbox.check-success .check-input:checked {
  background: #65C18C;
  border-color: #65C18C;
}
.dashboard-checkbox.check-success .check-input:checked + .checkbox-label {
  color: #65C18C;
}
.dashboard-checkbox .check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  line-height: 18px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0px;
  margin-top: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dashboard-checkbox .check-input::after {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.6) rotate(90deg);
  transform: scale(1.6) rotate(90deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.dashboard-checkbox .check-input:checked {
  background: var(--main-color-one);
  border-color: var(--main-color-one);
}
.dashboard-checkbox .check-input:checked::after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.2) rotate(0deg);
  transform: scale(1.2) rotate(0deg);
}
.dashboard-checkbox .check-input:checked + .checkbox-label {
  color: var(--main-color-one);
}
.dashboard-checkbox .checkbox-label {
  cursor: pointer;
  text-align: left;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: var(--heading-color);
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media only screen and (max-width: 575.98px) {
  .dashboard-checkbox .checkbox-label {
    font-size: 15px;
  }
}
.dashboard-checkbox .checkbox-label a {
  color: var(--main-color-one);
  font-weight: 500;
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content {
    z-index: 1;
    position: absolute;
    left: -100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 995;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    display: block;
    height: 100vh;
    transition: all 0.5s;
  }
  .dashboard-left-content.active {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 991.98px) and (max-width: 480px) {
  .dashboard-left-content {
    width: 280px;
  }
}
.dashboard-left-content .close-bars {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 24px;
  color: #fff;
  background: var(--main-color-one);
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  width: 35px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content .close-bars {
    visibility: visible;
    opacity: 1;
  }
}

.dashboard-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-icon {
    display: block;
  }
}
.dashboard-icon .sidebar-icon {
  font-size: 28px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  width: 45px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--customer-profile);
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  z-index: 95;
  border-radius: 5px;
  color: #fff;
  background-color: var(--main-color-one);
}

.dashboard-flex-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
}
@media only screen and (max-width: 480px) {
  .dashboard-flex-input {
    display: block;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  .dashboard-flex-input.flex-input-3 {
    display: block;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.dashboard-flex-input .dashboard-input {
  width: 100%;
}
.dashboard-flex-input .dashboard-input .form--control {
  width: 100%;
}

.dashboard-form .dashboard-input,
.dashboard-form .form-group {
  width: 100%;
  position: relative;
}
.dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
    font-size: 14px;
  }
}
.dashboard-form .dashboard-input .input-icon,
.dashboard-form .form-group .input-icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.dashboard-form .dashboard-input .iti,
.dashboard-form .form-group .iti {
  width: 100%;
}
.dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
  -moz-appearance: textfield;
  border-radius: 7px;
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.dashboard-form .dashboard-input .form--control.input-padding-left,
.dashboard-form .dashboard-input .form-control.input-padding-left,
.dashboard-form .form-group .form--control.input-padding-left,
.dashboard-form .form-group .form-control.input-padding-left {
  padding-left: 45px;
}
.dashboard-form .dashboard-input .form--control#phone,
.dashboard-form .dashboard-input .form-control#phone,
.dashboard-form .form-group .form--control#phone,
.dashboard-form .form-group .form-control#phone {
  width: 100%;
  padding-left: 50px;
}
.dashboard-form .dashboard-input .form--control::-webkit-outer-spin-button, .dashboard-form .dashboard-input .form--control::-webkit-inner-spin-button,
.dashboard-form .dashboard-input .form-control::-webkit-outer-spin-button,
.dashboard-form .dashboard-input .form-control::-webkit-inner-spin-button,
.dashboard-form .form-group .form--control::-webkit-outer-spin-button,
.dashboard-form .form-group .form--control::-webkit-inner-spin-button,
.dashboard-form .form-group .form-control::-webkit-outer-spin-button,
.dashboard-form .form-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.dashboard-form .dashboard-input .form--control:focus,
.dashboard-form .dashboard-input .form-control:focus,
.dashboard-form .form-group .form--control:focus,
.dashboard-form .form-group .form-control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
    font-size: 14px;
  }
}
.dashboard-form .dashboard-input .form-message,
.dashboard-form .form-group .form-message {
  height: 100px;
  line-height: 28px;
  padding: 15px;
}

.toggle-password {
  position: absolute;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
}

.toggle-password .eye-icon::before {
  content: "\f070";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.toggle-password.show-pass .eye-icon::before {
  content: "\f06e";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
