.banner-location-flex {
    ul.list, .list-datePicker{
        z-index: 5000 !important;
    }
    
    .banner-location-single {
        .banner-location-single-flex{
            .list, .list-datePicker{
                padding: 0 !important;
            }
            svg.css-i4bv87-MuiSvgIcon-root{
                margin-bottom: 15px;
            }
        }
    }
}

.checkoutdate{
    .rdp-months{
        width: 100% !important;
    }
    .rdp-month.rdp-caption_start.rdp-caption_end{
        width: 100% !important;
    }

    .rdp-table{
        width: 100% !important;
        max-width: none !important;
    }
}