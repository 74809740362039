.after-pannier{
    max-width: 1100px;
    background-color: #fbf7f2;
    margin: 50px auto;
    .all-pannier{
        padding: 20px;
        .select-empl{
            display: flex;
            flex-direction: column;
            gap: 10px;
            span{
                font-size: 0.9rem;
                font-weight: 600;
                color: #000;
            }
            .one-empl {
                .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root *{
                    font-family: CustomFont, sans-serif !important;
                }
            }
        }
        .select-date{
            display: flex;
            flex-direction: column;
            gap: 10px;
            span{
                font-size: 0.9rem;
                font-weight: 600;
                color: #000;
            }
        }
        .pannier-title{
            font-size: 1.05rem;
            width: 100%;
            font-weight: bold;
            color: #000;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(128, 128, 128, 0.6);
            margin-bottom: 10px;
        }
        .all-services-selected{
            display: flex;
            flex-direction: column;
            font-size: 0.9rem;
            color: #000;
            max-height: 600px;
            overflow-y: auto;
            .selected-service{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(162, 162, 162, 0.6);
                margin-bottom: 10px;
                .service-time{
                    display: flex;
                    flex-direction: column;
                    span:first-of-type{
                        font-weight: 600;
                    }
                    span:last-of-type{
                        
                    }
                }
                button{

                }
            }
        }
        .add-more-services{
            display: flex;
            gap: 5px;
            align-items: center;
            color: var(--main-color-one);
            margin-bottom: 20px;
            svg{
                font-size: 1.4rem;
            }
            span{
                font-weight: 600;
                cursor: pointer;
            }
        }

        .total-and-verify{
            margin-top: 10px;
            .total-text, .total-price{
                font-size: 0.95rem;
                font-weight: 600;
                text-align: right;
                color: #000;
            }
            .total-price{
                font-size: 20px;
                font-weight: 600;
                text-align: right;
                color: #000;
            }
            .verify-button{
                margin-top: 10px;
                float: right;
                background-color: var(--main-color-one);
                color: #000;
                border-radius: 8px;
                border: 1px solid var(--main-color-one);
                font-weight: 600;
                font-size: 1rem;
                padding: 10px 20px;
                min-width: 350px;
            }
        }
    }
    .all-pannier-free-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      }
      
      .all-pannier-free {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
      }
      
      .add-prestation {
        cursor: pointer;
        font-size: 1rem;
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--main-color-one);
        word-spacing: 1.5;
    }
      
      .add-prestation:hover {
        text-decoration: underline;
      }
      
}

@media screen and (max-width: 768px) {
    .after-pannier{
        margin: 0px;
        .pannier-title{
            margin-top: 20px;
        } 
    }       
}