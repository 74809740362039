.detail-blog-page{
    max-width: 1300px;
    margin: 30px auto;
    display: flex;
    gap: 5%;
    padding: 8px;
    .blogs-detail{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 80px;
        .contenu{
            img{
                width: 70%;
                min-width: 500px;
                margin: auto;
            }
            p{
                text-align: justify;
                color: #000;
            }
        }
        .single-blog{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 0 !important;
            gap: 20px;
            & > img {
                border-radius: 10px;
            }
            & > span.title {
                font-size: 1.2rem;
                color: #000;
                font-weight: 600;
            }   
            div.tags-comment-date{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                & > div {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    svg{
                        color: var(--main-color-one);
                        font-size: 18px;
                    }
                    span {
                        font-size: 0.85rem;
                        color: #000;
                    }
                }
            }
            div.author{
                display: flex;
                align-items: center;
                gap: 5px;
                svg{
                    color: var(--main-color-one);
                    font-size: 18px;
                }
                span {
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
            }
            .desc-blog{
                font-size: 0.95rem;
                color: #000;
            }

            button{
                min-width: 300px;
                border: 1px solid var(--main-color-one);
                background-color :var(--main-color-one);
                color: #fff;
                border-radius: 10px;
                font-weight: 600;
                font-size: 0.95rem;
                padding: 6px 12px;
            }
        }
    }
}


@media screen and (max-width: 1299px) {
    .detail-blog-page{
        margin: 30px 20px !important;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .detail-blog-page{
        flex-direction: column !important;
        .blogs-detail, .blog-meta-data{
            width: 100%;
        }
        .blogs-detail{
            order: 1;
        }.blog-meta-data{
            order: 2;
        }
        
    }
}

@media screen and (max-width: 400px) {
    .detail-blog-page .blogs-detail .single-blog button{
        margin: auto;
    }
}