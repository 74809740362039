.location-area {
    .tabs-container {
      background-color: #fff;
      padding: 10px;
    }
  
    .tabs {
      display: flex;
      justify-content: space-between;
  
      .tab-button {
        flex: 1;
        padding: 10px;
        border: none;
        background-color: #fff;
        color: #000;
        font-size: 0.7rem;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        justify-content: center;
        font-weight: 600;
        &:hover {
          background-color: #ddd;
        }
  
        .tab-icon {
          margin-right: 8px;
          vertical-align: middle;
        }
  
        &.active {
          background-color: #fff;
          color: orange;
          border-bottom: 2px solid orange;
        }
  
        &:focus {
          outline: none;
        }
      }
    }
  
    .tab-panel {
      padding: 20px;
      background-color: #fff;
    }
  }
  