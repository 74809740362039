.package-card {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .08);
    position: relative;
    overflow: hidden;
}

.package-card .package-card-img-wrap {
    position: relative;
    transition: all .5s ease-out;
}

.package-card .package-card-img-wrap .card-img {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 10px;
}

.package-card .package-card-img-wrap .card-img img {
    border-radius: 10px;
    transition: all .5s ease-out;
}
img {
    max-width: 100%;
    height: auto;
}

.package-card .batch {
    position: absolute;
    top: 5px;
    left: -20px;
    z-index: 1;
}
.package-card .batch .date {
    color: #fff;
    font-family: var(--font-rubik);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: .6px;
    text-transform: uppercase;
    display: inline-flex;
    padding: 8px 10px;
    background-color: #000;
    display: flex;
    align-items: center;
    gap: 5px;
    svg{
        font-size: 15px;
        color: var(--main-color-one);
    }
}
.package-card .batch .location {
    background-color: #fff;
    border: 1px solid var(--main-color-one);
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 7px;
    width: -moz-fit-content;
    width: fit-content;
}
.package-card .batch .location svg {
    fill: var(--primary-color1);
}
.package-card .batch .location .location-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    list-style: none;
    margin: 0;
    padding: 0;
}
.package-card .batch .location .location-list li:first-child {
    padding-left: 0;
}

.package-card .batch .location .location-list li {
    position: relative;
    line-height: 1;
    padding-left: 14px;
}
.package-card .batch .location .location-list li a {
    color: #000;
    font-family: var(--font-rubik);
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .6px;
    text-transform: uppercase;
}

.package-card .package-card-content .card-content-top {
    padding-top: 15px;
}

.package-card .package-card-content .card-content-top h5 {
    margin-bottom: 10px;
    transition: .5s;
}

.package-card .package-card-content .card-content-top h5 a {
    color: var(--title-color);
    font-family: var(--font-rubik);
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    text-transform: capitalize;
    transition: .5s;
}

.package-card .package-card-content .card-content-top .location-area2 {
    position: relative;
    height: 18px;
    overflow: hidden;
}

.package-card .package-card-content .card-content-top .location-area2 .location-list {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: translateX(0);
    transition: 8s;
    display: flex;
    align-items: center;
    gap: 35px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.package-card .package-card-content .card-content-top .location-area2 .location-list li {
    line-height: 1;
    position: relative;
    transition: .5s;
}

.package-card .package-card-content .card-content-top .location-area2 .location-list li a {
    color: #888;
    font-family: var(--font-rubik);
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .6px;
    text-transform: uppercase;
    transition: .5s;
}

.package-card .package-card-content .card-content-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(16, 12, 8, .2);
}

.package-card .package-card-content .card-content-bottom .price-area {
    line-height: 1;
}

.primary-btn2 {
    border-radius: 5px;
    background-color: var(--main-color-one);
    font-family: var(--font-rubik);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    letter-spacing: .48px;
    text-transform: capitalize;
    line-height: 1;
    // padding: 11px 16px;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    white-space: nowrap;
}

.package-card .package-card-content .card-content-bottom .price-area h6 {
    color: #333;
    font-family: var(--font-rubik);
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: .24px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.package-card .package-card-content .card-content-bottom .price-area span {
    color: var(--main-color-one);
    font-family: var(--font-rubik);
    font-size: 26px;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    text-transform: capitalize;
}

.package-card .package-card-content .card-content-bottom .price-area p {
    color: var(--text-color);
    font-family: var(--font-rubik);
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: .2px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.package-card .package-card-content .card-content-bottom .price-area span del {
    color: rgba(16, 12, 8, .5);
    font-size: 16px;
    font-weight: 500;
    padding-left: 5px;
}

.inner-pagination-area .pagination-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;
    ul{
        display: flex;
        align-items: center;
    }
}

.inner-pagination-area .pagination-list li:first-child {
    margin-right: 30px;
}

.inner-pagination-area .pagination-list li {
    margin-right: 20px;
}

.inner-pagination-area .pagination-list li svg {
    font-size: 1.9rem;
    border-radius: 50%;
    border: 1px solid #888;
    padding: 5px;
}