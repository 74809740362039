.arrow-button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.day.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.time-slot.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.time-slot.reserved {
  background-color: #f8cbc9 !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.time-slot .unavailable {
  background-color: #f0f0f0 !important;
  color: #999 !important;
  cursor: not-allowed !important;
}

.time-slot .status-message {
  font-size: 0.8em !important;
  color: #888 !important;
}

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  overflow: hidden;
  max-width: 330px;
  margin: auto;

  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 0 0;
    background: #fff;
    width: 100%;

    .month-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      padding: 0 5px;
    }

    .weeks {
      border-bottom: 1px solid #ccc;
      font-size: 0.85rem;
      font-weight: 400;

      .day {
        .date.active {
          background-color: var(--main-color-one);
          border-radius: 50%;
          color: #000;
          font-weight: 600;
        }
      }
    }

    .month {
      font-weight: bold;
    }

    .arrow-button {
      cursor: pointer;
      font-size: 20px;
    }

    .week-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;
      margin-bottom: 10px;
    }

    .day {
      cursor: pointer;
      padding: 10px;
      text-align: center;
      border: 1px solid transparent;
    }

    .day.active {
      background-color: lightblue;
    }

    .time-slots {
      flex-direction: column;
      align-items: center;
      height: 400px;
      width: 100%;
      overflow: hidden scroll;
      cursor: pointer;

    }

    .time-slot.active {
      background: var(--main-color-one);

      svg {
        color: black;
      }
    }

    .day.active .time-slots {
      display: flex;
    }

    .time-slot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 5px 30px;
      border-bottom: 1px solid #ccc;
      color: ccc;
    }

    .time-slot:last-of-type {
      border-bottom: unset;
    }
  }
}