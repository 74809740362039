.checkout-page{
    font-family: CustomFont, sans-serif !important;
    max-width: 1040px;
    display: flex;
    align-items: self-start;
    gap: 5%;
    margin: 35px auto;
    .data-client{
        width: 55%;
        .title-pay{
            font-size: 1.3rem;
            margin-bottom: 20px;
            font-weight: 600;
            color: #000;
        }
        form{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 10px;
            .modes{
                display: flex;
                gap: 10px;
                justify-content: space-between;
                button {
                    border: 1px solid var(--main-color-one);
                    border-radius: 4px;
                    padding: 10px;
                    background: transparent;
                    width: 33%;
                }
                button:hover, button.active{
                    border: 1px solid var(--main-color-one);
                    background: var(--main-color-one);
                }
            }
            button{
                background-color: var(--main-color-one);
                border: 1px solid var(--main-color-one);
                border-radius: 8px;
                font-weight: 600;
                padding: 6px 10px;
            }
        }
        .data-guest{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .info-line{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                span:first-of-type{
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: #000;
                }
                span:last-of-type{
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: var(--main-color-one);
                }
            }
            & > div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                label{
                    font-size: 0.95rem;
                    font-weight: 600;
                    color: #000;
                }
                input, textarea{
                    padding: 5px 10px;
                    font-size: 0.85rem;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
            }
        }
        .payment-box{
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 10px;
            .payment-info{
                .payment-title{
                    font-size: 1.3rem;
                    margin-bottom: 20px;
                    font-weight: 600;
                    color: #000;
                }
                .payment-description{
                    font-size: 0.75rem;
                    background-color: #fbf7f2;
                    width: 99%;
                    padding: 5px 10px;
                    margin: auto;
                }
            }
            .payment-method{
                .payment-method-info{
                    margin-top: 10px;
                    .payment-method-description{
                        font-size: 0.95rem;
                        font-weight: 600;
                        color: #000;
                    }
                }
                .group-cust-checks{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                    margin: 10px 0 14px 10px;
                    .cust-check-input{
                        padding: 12px 14px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        font-family: 'CustomFont', sans-serif !important;
                        label {
                            .css-ahj2mt-MuiTypography-root {
                                font-family: 'CUSTOMFONT', sans-serif !important;
                            }
                        }
                    }
                }
            }
            .choice{
                .paypal-choice, .cash-choice{
                    font-size: 0.85rem;
                    font-weight: 600;
                    color: #000;
                }
                .card-choice{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    & > span {
                        color: #000;
                        font-weight: 600;
                    }
                    & > div {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        width: 98%;
                        margin: auto;
                        label{
                            font-size: 0.95rem;
                            color: #000;
                        }
                        input{
                            padding: 10px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
        .checks-box{
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 10px;    
        }
    }
    .pannier-data{
        width: 40%;
        margin-top: 46px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 16px 10px;
        .info-res-salon{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            & > span{
                font-size: 1.7rem;
                font-weight: 600;
                color: var(--main-color-one);
                text-align: center;
            }
            .about-app{
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                span.time{
                    font-weight: 600;
                    font-size: 1.8rem;
                    color: #000;
                    margin-bottom: 10px;
                }
                .date-long{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 0.85rem;
                    span:first-of-type{
                        font-weight: 600;
                    }
                    span:last-of-type{
                    
                    }
                }
            }
            .list-services{
                margin-top: 10px;
                display: flex;
                gap: 10px;
                flex-direction: column;
                .each-service{
                    display: flex;
                    span{
                        font-size: 0.95rem;
                        color: #000;
                        font-weight: 600;
                        margin-left: 20px;
                    }
                }
                padding-bottom: 5px;
            }
            .total-time{
                margin: 0 20px;
                font-size: 0.93rem;
                border-bottom: 1px solid #eee;
                padding-bottom: 10px;
            }
            .montant{
                display: flex;
                justify-content: space-between;
                margin: 10px 20px;
                & > span {
                    font-weight: 600;
                }
            }
        }
    }
    .error input,
    .error textarea {
        border: 1px solid red;
    }

    .error span {
        color: red;
        font-size: 0.8rem;
    }

    .text-regle{
        font-size: 0.85rem;
        font-family: 'CustomFont', sans-serif;
        margin-bottom: 10px;
    }

    .caption{
        margin-top: 4px;
        font-size: 0.7rem;
        font-family: 'CustomFont', sans-serif;
    }

}

@media screen and (max-width: 768px) {
    .checkout-page{
        flex-direction: column;
        .data-client, .pannier-data{
            width: 95%;
            margin: auto;
        }
        .data-client{
            order: 2;
        }
        .pannier-data{
            order: 1;
            margin-bottom: 30px;
        }
    }   
}

.formselectpay{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-page {
    div.payment-method > div.MuiFormGroup-root.group-cust-checks.css-dmmspl-MuiFormGroup-root > label > span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root{
        width: 100% !important;
    }
}