.dashboard-area{
    svg{
        font-size: 20px;
    }

    .list.has-children{
        a {
            display: flex;
            span{
                margin-right: auto;
            }
        }
    }
}