.salon-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2%;

    .itemsalon{
        width: 22%;
        min-width: 300px;
        max-width: 400px;
    }
}

@media screen and (max-width: 768px){
    .section-title-three h2.title{
        margin: auto;
    }
    .salon-list.carousel-one{
        width: 100%;
        .carousel.slide{
            width: 100% !important;
            .carousel-indicators {
                margin-bottom: -30px !important;
                button{
                    background-color: gray;
                }
                button.active{
                    background-color: var(--main-color-one);
                }            
            }
            .carousel-control-prev, .carousel-control-next{
                display: none;
            }
        }
        .carousel-item .itemsalon{
            width: 100% !important;
            min-width: unset !important;
            max-width: unset !important;
            .hotel-view.radius-20{
                display: flex;
                .hotel-view-thumb{
                    width: 40%;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 550px){
    .salon-list.carousel-one{
        .carousel-item .itemsalon{

            .hotel-view.radius-20{
                display: flex;
                .hotel-view-thumb{
                    width: 40%;
                    height: auto;
                    min-height: 300px;
                }
            }

            .hotel-view-contents{
                .hotel-view-contents-header{
                    .hotel-view-contents-review{
                        font-size: 0.85rem;
                        svg{
                            font-size: 1.2rem;
                            margin-top: -3px;
                        }
                    }
                    .hotel-view-contents-title{
                        a{
                            font-size: 1.15rem !important;
                        }
                    }
                    .hotel-view-contents-location-para{
                        font-size: 0.8rem;
                    }
                }
                .hotel-view-contents-bottom{
                    .hotel-view-contents-bottom-title{
                        font-size: 20px;
                    }
                    .hotel-view-contents-bottom-para{
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}