.itemsalon{
    .hotel-view{
        box-shadow: 0 0 18px rgba(var(--main-color-one-rgb), 0.1);
        border-color: rgba(var(--main-color-one-rgb), 0.3);
        .hotel-view-thumb{
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .hotel-view-contents-title a{
        font-size: 1.3rem !important;
    }

    .hotel-view-contents-location{
        display: flex;
        align-items: center;
        .hotel-view-contents-location-para{
            margin-top: 2px;
            font-size: 0.9rem;
        }
    }

    .hotel-view-contents-bottom-para{
        font-size: 0.85rem;
    }

    .hotel-view-contents-bottom-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn-wrapper{
        align-self: flex-end;
        a{
            font-size: 0.85rem;
            padding: 2px 15px;
        }
    }
}
}

@media screen and (max-width: 648px){
    .itemsalon{
        width: 95% !important;
    }  
}