
.listingpagev2 {
    margin-top: 20px;

    .list-grid-product-wrap.list-group-wrapper .item {
        width: 100%
    }

    .list-grid-product-wrap.list-group-wrapper .item .package-card {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-img-wrap {
        max-width: 350px;
        flex-shrink: 0;
        width: 100%;
    }
////////////////////////////////////////////////////////////////////////////////////////
    .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-content {
        flex-grow: 1;
        /* Allow content to grow and take available space */
        display: flex;
        flex-direction: column;
        /* Stack content vertically */
        width: 60%;
    }

    .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-content .card-content-bottom {
        margin-top: auto;
        /* Push the price area to the bottom */
    }

    /* Adjust the size of images in list view mode */
    .list-grid-product-wrap.list-group-wrapper .item .package-card-img-wrap {
        max-width: 250px !important;
        /* Adjust this value as needed */
        width: 250px !important;
    }
    

    .list-grid-product-wrap.list-group-wrapper.list-view .item .package-card-img-wrap img {
        width: 100%;
        /* Make the image fit within its container */
        height: auto;
        /* Maintain aspect ratio */
    }

    /* Optional: Adjust image size for specific breakpoints if needed */
    @media(max-width: 1199px) {
        .list-grid-product-wrap.list-group-wrapper.list-view .item .package-card-img-wrap {
            max-width: 180px;
            /* Adjust for smaller screens */
        }
    }

    @media(max-width: 767px) {
        .list-grid-product-wrap.list-group-wrapper.list-view .item .package-card-img-wrap {
            max-width: 150px;
            /* Adjust for extra-small screens */
        }
    }


    @media(max-width: 1199px) {
        .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-img-wrap {
            max-width: 290px
        }
    }

    @media(min-width: 1200px) and (max-width:1399px) {
        .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-content .card-content-top h5 a {
            font-size: 20px
        }
    }

    @media(max-width: 767px) {
        .list-grid-product-wrap.list-group-wrapper .item {
            display: block
        }
    }


    .visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item {
        width: 50%
    }

    .visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 {
        display: block
    }

    .visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 .package-card-img {
        min-width: unset;
        max-width: unset;
        border-radius: 5px 5px 0 0
    }

    .visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 .package-card-img img {
        min-width: unset;
        max-width: unset;
        width: 100%;
        border-radius: 5px 5px 0 0;
        -o-object-position: center;
        object-position: center
    }

    .package-inner-title-section {
        display: flex;
        justify-content: flex-end;
        /* Space between title and grid/list view toggle */
        align-items: center;
    }

    .package-inner-title-section p {
        color: var(--text-color);
        font-family: var(--font-jost);
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .package-inner-title-section .selector-and-grid {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .package-inner-title-section .selector-and-grid .grid-view {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style: none;
        margin: 0;
    }

    .package-inner-title-section .selector-and-grid .grid-view li {
        line-height: 1;
        cursor: pointer;
    }

    .package-inner-title-section .selector-and-grid .grid-view li.active svg {
        fill: var(--main-color-one);
    }

    //loader 
    .circle-loader {
        position: relative;
        left: 50%;
        top: 350px;
        width: 55px;
        border: 3px dotted #fff;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotation 2s linear infinite;
        transform: translateX(-50%);
        display: none;
        z-index: 999;
    }

    // side bar style //
    .sidebar-area .single-widget {
        padding: 18px;
        border-radius: 5px;
        box-shadow: 0 0 25px 0 rgba(16, 16, 16, .05);
        margin-bottom: 30px;
    }

    .sidebar-area .widget-title {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: .9;
        letter-spacing: .4px;
        border-bottom: 1px solid #000;
        display: inline-block;
        margin-bottom: 30px;
    }

    .sidebar-area .single-widget .category-list {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 280px;
        overflow-y: auto;
    }

    .sidebar-area .single-widget .category-list.destination li {
        margin-bottom: 0;
    }
    .single-shop-left-filter-list .item::before {
        display: none !important;
    }

    .single-shop-left-filter-list .item {
        padding-left: 0 !important;
        .check-one{
            margin-right: -10px;
            margin-top: 5px;
        }
    }

    .sidebar-area .single-widget .category-list li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        transition: .5s;

        label {
            align-items: center;
            display: flex;
            gap: 10px;
        }
    }

    .single-widget ul li:first-child,
    .single-widget ol li:first-child {
        margin-top: 0;
    }

    form#tourSearch .form-inner {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            color: var(--main-color-one);
            cursor: pointer;
            font-size: 30px;
        }
    }

    @media screen and (max-width: 990px) {
        #tourSearchKeyword {
            padding: 6px 20px !important;
            height: 44px;
        }

        .second-part-of-listing{
            margin-top: -30px !important;
        }
    }

    .form-inner input {
        width: 100%;
        border-radius: 0;
        background: #fff;
        color: var(--title-color);
        font-family: var(--font-jost);
    }

    .range-wrap {
        padding-top: 10px;
    }

    .range-widget .ui-widget.ui-widget-content {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 3px;
        margin-bottom: 85px;
    }

    .range-widget .ui-slider-horizontal {
        height: 8px;
    }
}

.ul-no-scrollbar::-webkit-scrollbar {
    display: none;
}

.ul-no-scrollbar {
    scrollbar-width: none;
}

.ul-no-scrollbar {
    -ms-overflow-style: none;
    overflow-x: auto;
    white-space: nowrap;
}


.pagination-comp{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.pagination-list-item-button{
    cursor: pointer;
}