.breadcrumb-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0 130px;
    background-image: linear-gradient(270deg, rgb(255 162 67), #212529 101.02%);
    // linear-gradient(270deg, rgb(243 157 68), #3d1d83 101.02%)
}

.breadcrumb-section .banner-content h1 {
    color: #fff;
    text-align: center;
    font-family: var(--font-rubik);
    font-size: 52px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
    line-height: 1.2;
    word-break: break-all;
}

.breadcrumb-section .banner-content .breadcrumb-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadcrumb-section .banner-content .breadcrumb-list li:first-child {
    padding-left: 0;
    color: #000;
    font-weight: 400;
}
.breadcrumb-section .banner-content .breadcrumb-list li {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
}
.breadcrumb-section .banner-content .breadcrumb-list li.active {
    svg{
        font-size: 25px;
        padding-right: 10px;
    }
}