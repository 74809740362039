.bannertwo{
    .banner-area-flex {
        display: flex;
        // align-items: flex-end;
        justify-content: space-between;
        gap: 25px;
    }

    .location-area{
        max-width: 500px;
        min-width: 400px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .banner-area-two{
        padding-top: 0 !important;
        .banner-area-thumb{
            img{
                max-width: 700px !important;
                min-width: 600px !important;
            }
        }
        .banner-single-content-title{
            font-size: 45px !important;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bannertwo{
        .banner-area-two{
            .banner-area-thumb{
                img{
                    max-width: 550px !important;
                    min-width: 450px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .bannertwo{
        .location-area{
            width: 90% !important;
            max-width: unset !important;
            min-width: unset !important;
            margin: 20px auto !important;
        }
        .banner-area-two{
            .banner-area-thumb{
                img{
                    max-width: 400px !important;
                    min-width: 350px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bannertwo{
        .banner-area-two{
            .banner-area-thumb{
                img{
                    max-width: 350px !important;
                    min-width: 300px !important;
                }
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .bannertwo{
        .banner-area-two{
            .banner-area-thumb{
                img{
                    max-width: 300px !important;
                    min-width: 250px !important;
                }
            }
        }
    }
}
