.blog-page{
    max-width: 1300px;
    margin: 30px auto;
    display: flex;
    gap: 5%;
    padding: 8px;
    .blogs-list{
        width: 66%;
        display: flex;
        flex-direction: column;
        gap: 80px;
        .single-blog{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 0 !important;
            gap: 20px;
            & > img {
                border-radius: 10px;
            }
            & > span.title {
                font-size: 1.2rem;
                color: #000;
                font-weight: 600;
            }   
            div.tags-comment-date{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                & > div {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    svg{
                        color: var(--main-color-one);
                        font-size: 18px;
                    }
                    span {
                        font-size: 0.85rem;
                        color: #000;
                    }
                }
            }
            div.author{
                display: flex;
                align-items: center;
                gap: 5px;
                svg{
                    color: var(--main-color-one);
                    font-size: 18px;
                }
                span {
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
            }
            .desc-blog{
                font-size: 0.95rem;
                color: #000;
            }

            button{
                min-width: 300px;
                border: 1px solid var(--main-color-one);
                background-color :var(--main-color-one);
                color: #fff;
                border-radius: 10px;
                font-weight: 600;
                font-size: 0.95rem;
                padding: 6px 12px;
            }
        }
    }
    .blog-meta-data{
        width: 29%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        & > div {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            padding: 10px 14px;
        }

        div.search{
            input{
                width: 100%;
                border: unset;
                font-size: 0.85rem;
            }
        }

        .blog-categories {
            padding: 10px 0;
            span.title{
                font-size: 1.1rem;
                font-weight: 600;
                color: var(--main-color-one);
                margin-bottom: 10px;
                padding: 10px 14px;
            }
            .list-categ{
                display: flex;
                flex-direction: column;
                gap: 5px;
                font-size: 0.8rem;
                & > div {
                    padding: 0 14px;
                    cursor: pointer;
                }
                & > div.active{
                    border-left: 4px solid var(--main-color-one);
                    font-weight: 600;
                    color: #000;
                }
            }
        }
        .popular-blogs {
            padding: 10px 0;
            span.title{
                font-size: 1.1rem;
                font-weight: 600;
                color: var(--main-color-one);
                margin-bottom: 10px;
                padding: 10px 14px;
            }
            .list-popular{
                display: flex;
                flex-direction: column;
                gap: 20px;
                font-size: 0.8rem;
                & > div {
                    border-left: 4px solid var(--main-color-one);
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    gap: 14px;
                    cursor: pointer;
                    img {
                        width: auto;
                        max-height: 75px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 8px;
                    }
                    .title-date{
                        display: flex;
                        flex-direction: column;
                        font-size: 0.8rem;
                        line-height: 1.5;
                        gap: 10px;
                        span:first-of-type{
                            font-weight: 600;
                            color: #000;
                        }
                        span:last-of-type{
                            font-weight: 400;
                            font-size: 0.75rem;
                        }
                    }

                    .title-date span:first-of-type:hover{
                        color: var(--main-color-one);
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1299px) {
    .blog-page{
        margin: 30px 20px !important;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .blog-page{
        flex-direction: column !important;
        .blogs-list, .blog-meta-data{
            width: 100%;
        }
        .blogs-list{
            order: 1;
        }.blog-meta-data{
            order: 2;
        }
        
    }
}

@media screen and (max-width: 400px) {
    .blog-page .blogs-list .single-blog button{
        margin: auto;
    }
}