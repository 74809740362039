.apropos-page{
    margin: 40px 0;
    span, p{
        font-size: .85rem;
    }
    .big-title{
        font-size: 1.25rem;
        color: var(--main-color-one);
        font-weight: 600;
        margin-bottom: 10px;
    }
    .small-title{
        font-size: 0.95rem;
        margin-top: 10px;
        color: #000;
        font-weight: 600;
    }   
}