@font-face {
    font-family: 'CustomFont';
    src: url('./../../assets/fonts/century-gothic/centurygothic.ttf') format('woff2');
}

*{
    font-family: CustomFont, sans-serif !important;
}
.second-page{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
