.beauty-salon-plan-content {
    width: 100%;
}

.beauty-salon-plan-content.aucun-service{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  
  .item-beauty-salon-plan {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  .item-beauty-salon-plan:last-of-type {
    border-bottom: unset;
  }
  
  .beauty-salon-plan-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 0 16px 0;
  }
  
  .beauty-salon-plan-day,
  .beauty-salon-plan-label {
    font-weight: bold;
    color: black;
  }
  
  .icomoon-chevron-down,
  .icomoon-chevron-up {
    transition: transform 0.3s;
  }
  
  .icomoon-chevron-up {
    transform: rotate(180deg);
  }
  
  .beauty-salon-plan-description {
    margin-top: 10px;
    font-weight: 500;
    color: #000;
    .all-details{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .detail-each{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 80%;
        .first-line, .second-line{
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      .select-service{
        background: var(--main-color-one);
        color: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 8px 16px;
        font-weight: 400;
      }
      .select-service.selected{
        background: #f1fff8;
        color: #008354;
        font-weight: 600;
        border: unset;
        border-radius: 10px;
      }
    }

  }
  
  [id^="vertical-tabpanel-"], [id^="tabpanel-"]{

    width: -webkit-fill-available;
  }

  .css-19kzrtu{
    padding: 0 24px !important;
  }

  @media screen and (max-width: 600px) {
    .css-19kzrtu{
      padding: 16px 5px 0px !important;
    }
    .beauty-salon-plan-content{
      .beauty-salon-plan-label {
        font-size: 0.85rem;
      }
      .beauty-salon-plan-day {
        font-size: 0.85rem;
        font-weight: 600;
      }
      .beauty-salon-plan-title{
          div {
            display: flex;
          }
      }

      .beauty-salon-plan-description{
        ul{
          padding: 0;
          div.all-details{
            align-items: center;
            .detail-each{
              font-size: 0.9rem;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
