// .detail-page {
//     .title-part {
//         font-weight: bold;
//         font-size: 1.40rem;
//         font-family: CustomFont, sans-serif;
//     }

//     @media screen and (max-width : 600px) {
//         .title-car {
//             padding: 20px 0 0 0 !important;

//             .meta-data {
//                 .title-detail {
//                     font-size: 30px !important;
//                     margin-bottom: 10px !important;
//                 }
//             }
//         }
//     }

//     .title-car {
//         padding: 70px 0 0 0;
//         background-color: #fbf7f2;

//         .meta-data {
//             padding-left: 10px;
//             padding-right: 10px;
//             display: flex;
//             flex-direction: column;

//             .title-detail {
//                 margin: 0;
//                 font-weight: 700;
//                 font-size: 42px;
//                 line-height: 54px;
//                 letter-spacing: -0.5px;
//                 color: #000;
//                 margin-bottom: 20px;
//             }

//             .location-rev {
//                 display: flex;
//                 flex-wrap: wrap;
//                 margin-bottom: 35px;

//                 &>div {
//                     margin-right: 30px;
//                     display: flex;
//                 }

//                 div.loc-one {
//                     svg {
//                         color: red;
//                         margin-right: 7px;
//                     }

//                     span {
//                         font-weight: 400;
//                         font-size: 16px;
//                         color: #000;
//                     }
//                 }

//                 div.rev-one {
//                     svg {
//                         color: gold;
//                         line-height: 10px;

//                     }

//                     span {
//                         margin-left: 5px;
//                         font-weight: 400;
//                         color: #000;
//                     }
//                 }

//                 div.open-status {
//                     svg {
//                         color: navy;
//                         line-height: 10px;
//                         margin-right: 10px;
//                     }

//                     span {
//                         font-weight: 400;
//                         color: #000;
//                     }
//                 }
//             }
//         }

//         .slider-images-salon {
//             .carousel-container {
//                 position: relative;
//                 margin: 0 auto;
//             }

//             .carousel-container {
//                 width: 100%;
//                 /* Adjust the width as needed */
//                 margin: 0 0.4%;
//                 /* Center the carousel horizontally */
//             }

//             .slick-slide img {
//                 width: 97%;
//                 /* Make images fill the width of their container */
//                 height: 330px;
//                 /* Set a fixed height for all images */
//                 object-fit: cover;
//                 object-position: center;
//             }

//             @media screen and (max-width: 600px) {
//                 .slick-slide img {
//                     width: 100%;
//                     /* Make images fill the width of their container */
//                 }
//             }

//         }

//         .services-salon {
//             display: flex;
//             flex-wrap: wrap;
//             column-gap: 24px;
//             row-gap: 34px;
//             align-items: center;
//             // justify-content: space-between;
//             padding: 55px 0;

//             .single-serv {
//                 display: flex;
//                 align-items: center;
//                 justify-content: flex-start;
//                 gap: 10px;
//                 // width: calc(20% - 50px);
//                 width: fit-content;

//                 svg {
//                     font-size: 25px;
//                     color: var(--main-color-one);
//                     line-height: 1;
//                 }

//                 .title-desc {
//                     display: flex;
//                     flex-direction: column;

//                     .title {
//                         // margin: 0 0 10px;
//                         font-weight: 700;
//                         font-size: 14px;
//                         line-height: 20px;
//                         color: #000;
//                     }

//                     .desc {
//                         margin: 0;
//                         font-weight: 700;
//                         font-size: 16px;
//                         line-height: 20px;
//                         color: #2a2a2a;
//                         opacity: 0.7;
//                     }
//                 }
//             }
//         }
//     }

//     .detail-body {
//         gap: 50px;
//         background-color: #fff;

//         .overview {
//             text-align: justify;

//             p {
//                 font-size: 0.85rem;
//             }
//         }
//     }

//     .avis-comment {
//         .avis-des {
//             display: flex;
//             align-items: flex-end;
//             gap: 20px;
//             margin: 20px 0;

//             .numbers {
//                 .number-stars {
//                     color: gold;
//                     font-size: 4.5rem;
//                     font-weight: 600;
//                 }

//             }

//             .stars-avis {
//                 display: flex;
//                 flex-direction: column;

//                 .number-avis {
//                     font-size: 0.9rem;
//                     font-weight: 400;
//                     margin-left: 5px;
//                 }

//                 .stars {
//                     svg {
//                         color: gold;
//                         font-size: 2.5rem;
//                     }
//                 }
//             }
//         }
//     }

//     .certified-avis {
//         margin-top: 20px;
//         box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
//         border: 1px solid rgba(0, 0, 0, .1);
//         border-radius: 4px;
//         padding: 14px 20px;
//         display: flex;
//         align-items: center;
//         gap: 20px;

//         svg {
//             color: green;
//         }

//         div {
//             font-size: 0.85rem;

//             span {
//                 font-weight: 600;
//             }

//             p {
//                 font-size: inherit;
//             }
//         }
//     }

//     .filter-avis {
//         box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
//         border: 1px solid rgba(0, 0, 0, .1);
//         border-radius: 4px;
//         padding: 14px 20px;

//         .filter-by-type {
//             span {
//                 font-weight: 600;
//             }
//         }

//         .filter-by-eva {
//             margin-top: 4px;

//             span {
//                 font-weight: 600;
//             }

//             .filter {
//                 margin: 0px 10px;

//                 svg.filled-one {
//                     color: gold;
//                 }

//                 svg.not-filled-one {
//                     color: gold;
//                     opacity: 0.5;
//                 }

//                 svg.checkone {
//                     color: black !important;
//                     width: 20px;
//                 }
//             }
//         }
//     }

//     .list-reviews {
//         padding: 10px;

//         .review {
//             padding-bottom: 0.5rem; // Example styling, adjust as needed
//             margin-bottom: 0.5rem; // Example styling, adjust as needed
//             border-bottom: 1.5px solid #eee;

//             .stars {
//                 display: flex;
//                 align-items: center;
//                 color: gold;
//             }

//             .comment {
//                 margin-top: 0.5rem;
//                 font-size: 1rem;
//                 // Add more styles as needed
//             }

//             .realized-by {
//                 margin-top: 0.2rem;
//                 font-size: 0.9rem;
//                 color: #838ca3;

//                 .client-name {
//                     font-weight: bold;
//                     color: #838ca3;
//                 }

//                 .service-name {
//                     padding: 0px 8px;
//                     font-size: 0.85rem;
//                     font-weight: bold;
//                     color: #333;
//                     border: 1px solid #33333392;
//                     border-radius: 10px;
//                 }
//             }

//             .client-date-both {
//                 display: flex;
//                 gap: 10px;

//                 .client-verified {
//                     margin-top: 0rem;
//                     display: flex;
//                     align-items: center;
//                     color: #4caf50; // Example color for verified status

//                     span {
//                         margin-right: 0.5rem;
//                         font-size: 0.85rem;
//                         font-weight: 600;
//                         color: #000;
//                     }

//                     svg {
//                         color: #4caf50;
//                     }
//                 }

//                 .date-time {
//                     margin-top: 0rem;
//                     font-size: 0.8rem;
//                     color: #999;
//                 }
//             }
//         }

//         .review:last-child {
//             border-bottom: unset;
//         }
//     }

//     .pagination-controls {
//         text-align: center !important;
//         // svg{
//         //     color: var(--main-color-one);
//         // }
//         // svg.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled{
//         //     fill: rgba(0, 0, 0, 0.26) !important;
//         // }
//     }

//     @media screen and (max-width: 600px) {
//         .employee-ratings {
//             display: block !important;

//             .employee-rating {
//                 padding: 10px;
//             }
//         }
//     }

//     .employee-ratings {
//         width: 100%;
//         display: flex;
//         gap: 20px;
//         margin-bottom: 20px;

//         .css-1hh1abp,
//         .css-p6ujzv {
//             flex-grow: 0;
//         }

//         .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
//             align-items: flex-start !important;
//         }

//         .rating-stars {
//             display: flex;
//             align-items: center;
//             gap: 10px;

//             svg {
//                 color: gold;
//             }
//         }

//         .services {
//             span {
//                 font-weight: 600;
//                 font-size: 1.1rem;
//                 color: #000;
//                 margin: 8px 0;
//             }

//             .all-services {
//                 padding-left: 20px;
//                 display: flex;
//                 flex-wrap: wrap;
//                 gap: 10px;

//                 .each-service {
//                     padding: 0px 8px;
//                     font-size: 0.85rem;
//                     font-weight: bold;
//                     color: #333;
//                     border: 1px solid #33333392;
//                     border-radius: 10px;
//                     width: fit-content;
//                 }
//             }
//         }
//     }

//     .map-comp {
//         @media screen and (max-width: 600px) {
//             .map-area {
//                 &>div {
//                     display: flex;
//                     flex-direction: column;

//                     .loc-one {
//                         margin-bottom: 20px;
//                     }
//                 }
//             }

//         }

//         .map-area {
//             &>div {
//                 display: flex;
//             }

//             background-color: #fbf7f2;

//             div.loc-one {
//                 padding-top: 20px;
//                 padding-left: 20px;
//                 display: flex;
//                 gap: 10px;

//                 svg {
//                     color: var(--main-color-one);
//                     margin-top: 0px;
//                 }

//                 div {
//                     display: flex;
//                     flex-direction: column;

//                     span:first-of-type {
//                         font-weight: 600;
//                         font-size: 20px;
//                     }

//                     span {
//                         font-weight: 400;
//                         font-size: 14px;
//                         color: #000;
//                     }
//                 }
//             }

//             #map {
//                 .search {

//                     // height: 280px;
//                     div {
//                         input {
//                             width: -webkit-fill-available;
//                             padding: 5px;
//                             border: 1px solid #db962f;
//                         }

//                         button {
//                             width: 107px;
//                             border: 1px solid #5c5545;
//                             background: #5c5545;
//                             color: #fff;
//                             padding: auto 4px;
//                         }
//                     }

//                     .listofcities {
//                         max-height: 250px;
//                         overflow-y: hidden scroll;
//                         overflow-x: hidden;
//                     }

//                     .cityname span {
//                         font-size: 0.7rem !important;
//                     }
//                 }

//                 .themap {
//                     overflow: hidden;
//                     height: 280px;
//                 }
//             }

//             .leaflet-container {
//                 height: 100vh;
//             }

//             .leaflet-container img {
//                 max-height: 100%;
//             }

//             .rmdp-container {
//                 input.rmdp-input {
//                     border: 1px solid #db962f;
//                     border-radius: 0;
//                 }
//             }

//             .rmdp-container>div:nth-of-type(2) {
//                 z-index: 1000 !important;
//             }

//             .rmdp-panel-body li,
//             .rmdp-panel-body li.bg-blue,
//             .rmdp-day.rmdp-selected span:not(.highlight),
//             .rmdp-arrow-container:hover {
//                 background: #db962f;
//             }

//             .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//                 background: #9c6c23;
//             }

//             .rmdp-day.rmdp-today span {
//                 background: #908a7a;
//             }

//             .rmdp-week-day {
//                 color: #5c5545;
//             }

//             .rmdp-arrow {
//                 border-color: #5c5545;
//             }
//         }
//     }

//     @media screen and (max-width: 768px) {
//         .pannier {
//             width: 100% !important;
//         }
//     }

//     @media screen and (max-width: 450px) {
//         .pannier {
//             width: 100% !important;

//             .prestations {
//                 .numberpres-price {
//                     gap: 2px !important;

//                     .numberpres {
//                         font-size: 1.05rem !important;
//                         font-weight: bold !important;
//                     }

//                     .price {
//                         font-size: 0.7rem !important;
//                     }
//                 }
//             }

//             button.choisir {
//                 font-size: 1.05rem !important;
//                 font-weight: bold !important;
//             }
//         }
//     }

//     .pannier {
//         position: fixed;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 100px; // Adjust the height as needed
//         background-color: #ffffff; // Background color of the pannier
//         color: #000; // Text color inside the pannier
//         display: flex;
//         justify-content: space-between; // Center content horizontally
//         align-items: center; // Center content vertically
//         box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); // Optional shadow for a nice effect
//         z-index: 1000; // Ensure it's above other content
//         padding-left: 16px;
//         width: 90%;
//         max-width: 1024px;
//         left: 50%;
//         transform: translateX(-50%);

//         .prestations {
//             .numberpres-price {
//                 display: flex;
//                 flex-direction: column;
//                 gap: 10px;

//                 .numberpres {
//                     font-size: 1.25rem;

//                     .pres-price {
//                         font-weight: bold;
//                         color: #ba5e00;
//                     }
//                 }

//                 .price {
//                     font-size: 0.7rem;
//                     line-height: 1.5;
//                 }
//             }
//         }

//         button.choisir {
//             background: var(--main-color-one);
//             color: black;
//             height: -webkit-fill-available;
//             padding: 10px;
//             border: unset;
//             font-size: 1.25rem;
//             text-align: center;
//             font-weight: 400;
//         }
//     }

// }

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}