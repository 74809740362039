:root {
    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 40px;
  }
  
  
  .NearMeFooter-module--grid--d030e0 {
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: var(--spacing-lg);
    padding: 20px 5%;
}

.NearMeFooter-module--treatment--f64511 {
    --Badge--large-size: 24px;
    background-color: var(--color-white);
    border-radius: var(--spacing-xxs);
    padding: var(--spacing-md)
}

.NearMeFooter-module--arrow--be83c2 {
    transform: rotate(180deg)
}

.NearMeFooter-module--cities--cdcdc4 {
    display: none!important;
    margin: 0 var(--spacing-xs)
}

@media (min-width: 520px) {
    .NearMeFooter-module--title--2971e2 {
        margin:0 0 calc(var(--spacing-xl) * 2);
        text-align: center;
    }

    .NearMeFooter-module--grid--d030e0 {
        grid-template-columns: repeat(2,minmax(0,1fr));
        grid-row-gap: 30px;
    }

    .NearMeFooter-module--treatment--f64511 {
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid var(--color-grey-30);
        padding: var(--spacing-md) var(--spacing-xs)
    }

    .NearMeFooter-module--cities--cdcdc4 {
        display: inherit!important
    }
}

@media (min-width: 992px) {
    .NearMeFooter-module--grid--d030e0 {
        grid-template-columns:repeat(4,minmax(0,1fr))
    }
}

@media (min-width: 520px) and (max-width: 768px){
    .HomePage-module--pageContents--fdd270 {
        max-width: 1160px;
        padding-top: calc(var(--spacing-xl)* 2);
        padding-bottom: calc(var(--spacing-xl)* 2);
    }
}

.Inline-module_alignCenter__uIC18 {
    align-items: center !important;
}
.Inline-module_justifyStart__2svO3 {
    justify-content: flex-start !important;
}
.Inline-module_inline__1aHBb {
    display: flex !important;
}

.Inline-module_inline__1aHBb>* {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.Badge-module_lg__1vCFV {
    height: var(--Badge--large-size);
    width: var(--Badge--large-size);
}
.Badge-module_badge__dq1Zm {
    align-items: center;
    border-radius: var(--Badge-borderRadius);
    display: flex;
    justify-content: center !important;
    overflow: hidden;
}
.Inline-module_inline__1aHBb .Inline-module_splitAfter__1qpg- {
    margin-right: auto !important;
    margin-left: 10px !important;
}

.Inline-module_xs__1AzT4 > *+* {
    margin-left: var(--Inline-xs-space) !important;
}
.Inline-module_inline__1aHBb > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

svg:not(:root) {
    overflow: hidden;
}

.NearMeFooter-module--arrow--be83c2 {
    transform: rotate(180deg);
}

.Stack-module_stack__19jn1 {
    padding: 2px 20px;
    border-radius: 8px;
    .Inline-module_inline__1aHBb{
        color: black;
    }
}

.service {
    margin-top: 60px !important;
    .coiffeur {
        span{
            font-weight: 600;
        }
      background-color: #ffe5d8;
      .coiffeur-icon, .coiffeur-title, .coiffeur-arrow{
        color: #4b2e2a;  // Dark brown text
      }
    }
  
    .barbier {
        span{
            font-weight: 600;
        }
      background-color: #d8efff;
      .barbier-icon, .barbier-title, .barbier-arrow{
        color: #0f2d54;  // Dark blue text
      }
    }
  
    .massage {
        span{
            font-weight: 600;
        }
      background-color: #d8ffd8;
      .massage-icon, .massage-title, .massage-arrow{
        color: #2a4b2e;  // Dark green text
      }
    }
  
    .institut {
        span{
            font-weight: 600;
        }
      background-color: #ffd8ef;
      .institut-icon, .institut-title, .institut-arrow{
        color: #a02c57;   
      }
    }
  }
  