.current-menu-item a{
    color: var(--main-color-one) !important;
}

li.menu-item-has-children .sub-menu {
    position: absolute;
    text-align: left;
    min-width: 220px;
    margin: 0;
    padding: 0;
    list-style: none;
    left: 0;
    top: 100%;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 992;
    border-bottom: 5px solid var(--main-color-one);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}